import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import AccountOverviewTab from "./AccountOverviewTab.component";
import { setEditAlertModal } from "../../../redux/modals/action";
// Custom Carousel Component
import CarouselComponent from "../../../components/CarouselComponent/Carousel.component";

import {
  AccountPageStyled,
  CarouselWrapperDiv,
  AccountTypeTopHeader,
  AccountTypeHeadDetail,
  DetailsWrapper,
  AccountTypeDataDescp,
  AccountTabTabDiv,
  NoSectionStyle,
} from "./AccountOverview.component.styles";

const AccountOverview = ({
  AccountOverview,
  imageLoaded,
  setImageLoaded,
  AccountLoading,
  AccountDetailLoading,
  editForm,
}) => {
  const [editOverview, setEditOverview] = useState(true);
  const [accountDetails, setAccountDetails] = useState({});

  const carouselData = [
    {
      name: "Worksites",
      iconClass: "worksites",
      feildName: "sites",
      type: "ThemePrimary",
      iconColor: "GrayL4",
    },
    {
      name: "Assets",
      iconClass: "assets",
      feildName: "asset",
      type: "ThemePrimary",
      iconColor: "GrayL4",
    },
    {
      name: "Users",
      iconClass: "users",
      feildName: "users",
      type: "ThemePrimary",
      iconColor: "GrayL4",
    },
  ];

  const countData = {
    sites: AccountOverview?.sites?.length || 0,
    asset: AccountOverview?.asset?.length || 0,
    users: AccountOverview?.users?.length || 0,
  };

  useEffect(() => {
    let { companyName, enterpriseUrl, unitType, timeZone } = AccountOverview
      ? AccountOverview
      : { companyName: "" };
    setAccountDetails({
      companyName,
      enterpriseUrl,
      unitType,
      timeZone,
    });
    setEditOverview(true);
  }, [AccountOverview, editForm]);

  const truncateText = (text, limit = 24) =>
    text && text.length > limit ? `${text.substring(0, limit)}...` : text;

  return (
    <>
      <AccountPageStyled>
        <>
          {(AccountLoading || AccountDetailLoading) && (
            <Row>
              <Col md={6}>
                <Skeleton height={40} />
                <Skeleton height={10} count={1} />
              </Col>
              <Col md={6}>
                <Skeleton height={50} width={200} />
              </Col>
            </Row>
          )}
          {AccountOverview && !AccountLoading && !AccountDetailLoading && (
            <Row>
              <Col md={6} style={{ zIndex: editForm ? 0 : 1 }}>
                <AccountTypeTopHeader>
                  <DetailsWrapper>
                    {/* {AccountOverview?.companyName ? (
                      <AccountTypeHeadDetail title={AccountOverview && AccountOverview.companyName}>
                        {AccountOverview && AccountOverview.companyName}
                      </AccountTypeHeadDetail>
                    ) : (
                      <AccountTypeHeadDetail title={AccountOverview && AccountOverview.email}>
                        {AccountOverview && AccountOverview.email}
                      </AccountTypeHeadDetail>
                    )} */}
                    <AccountTypeHeadDetail title={AccountOverview?.companyName || AccountOverview?.email}>
                      {truncateText(AccountOverview?.companyName || AccountOverview?.email)}
                    </AccountTypeHeadDetail>

                    <AccountTypeDataDescp title={AccountOverview && AccountOverview.companyDescription}>
                      {AccountOverview && AccountOverview.companyDescription}
                    </AccountTypeDataDescp>

                    <AccountTypeDataDescp>
                      <Trans>Added On</Trans>:
                      {moment(AccountOverview?.createdAt)
                        .utcOffset(
                          AccountOverview?.timeZone &&
                            AccountOverview?.timeZone.split &&
                            AccountOverview?.timeZone.split(" ")[0]
                        )
                        .format(" MMMM DD YYYY")}
                    </AccountTypeDataDescp>
                  </DetailsWrapper>
                </AccountTypeTopHeader>
              </Col>
              <Col md={6}>
                {/* <MultiCards /> */}
                <CarouselWrapperDiv>
                  <CarouselComponent
                    responsive={{
                      superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
                      desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
                      tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
                      mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
                    }}
                    countData={countData}
                    carouselData={carouselData}
                    autoPlay={false}
                    autoPlaySpeed={5000}
                    slidesToSlide={1}
                    size="small"
                    pagination={false}
                  />
                </CarouselWrapperDiv>
              </Col>
            </Row>
          )}
          {!AccountDetailLoading && AccountOverview && (
            <AccountTabTabDiv>
              <AccountOverviewTab
                accountDetails={accountDetails}
                setAccountDetails={setAccountDetails}
                setEditOverview={setEditOverview}
                editOverview={editOverview}
                imageLoaded={imageLoaded}
                setImageLoaded={(data) => setImageLoaded(data)}
              />
            </AccountTabTabDiv>
          )}
          {!AccountOverview && !AccountLoading && !AccountDetailLoading && (
            <NoSectionStyle>
              <p>
                <Trans>NO_ACCOUNT_IN_ACCOUNT</Trans>
              </p>
              {/* <Trans>CLICK_ADD_ACCOUNT_BUTTON</Trans> */}
            </NoSectionStyle>
          )}
        </>
      </AccountPageStyled>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  AccountOverview: state.accounts.AccountOverview,
  AccountLoading: state.accounts.AccountLoading,
  AccountDetailLoading: state.accounts.accountDetailLoading,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountOverview));
