import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Trans } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";

import { CSVButtonWrapper, CustomDropdown, ClickWrapper, OptionText } from "./ExportToCSV.styles";
import { csvHeadersData } from "./constants";
import SnackBar from "../../../Dashboard/SnackBar";
import DownloadAllReportModal from "../../Modals/DownloadAllReportModal";
import Button from "../../../../components/Button/Button.component";
import ModalPopup from "../../../../components/ModalPopupComponent/ModalPopup.component";
import DownloadBulkLogsFilters from "./DownloadBulkLogsFilters.component";
import { getTimeWithTimeZone } from "../../../../utils/timezoneConvert";

const ExportToCSV = (props) => {
  const { downloadReportSet, reportList, isDownloadAllSelected, currentTimeZone } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleCloseSnackBar = () => {
    setIsError(false);
  };

  useEffect(() => {
    if (downloadReportSet.size <= 0) {
      setShowMenu(false);
    }
  }, [downloadReportSet]);

  const getSelectedLoginRecords = () => reportList.filter((report) => downloadReportSet.has(report._id));

  const generateLoginRecordsData = () => {
    const selectedLoginRecordsArr = getSelectedLoginRecords();

    const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZoneShort = moment.tz(timeZoneString).format("z");

    return selectedLoginRecordsArr.map((data) => {
      data.accountName = data?.accountId?.companyName;
      data.siteName = data?.siteId?.name;
      data.assetName = data?.assetId?.assetName;
      data.assetType = data?.assetTypeId?.AssetTypeName;
      data.userName = data?.userId?.fullName;
      if (data?.loginTime && data?.logOutTime) {
        const loginTime = new Date(data.loginTime);
        const logOutTime = new Date(data.logOutTime);
        const differenceInSeconds = Math.floor((logOutTime - loginTime) / 1000);
        const hours = Math.floor(differenceInSeconds / 3600);
        const minutes = Math.floor((differenceInSeconds % 3600) / 60);
        const seconds = differenceInSeconds % 60;

        let formattedTime = "";

        if (hours > 0) {
          formattedTime += `${hours}h `;
        }
        if (minutes > 0) {
          formattedTime += `${minutes}m `;
        }
        formattedTime += `${seconds}s`;

        data.loggedInDuration = formattedTime.trim();
      }

      data.numberOfInspections = data?.data?.inspection.passed + data?.data?.inspection.failed;
      data.firstInspectionTime = data?.data?.inspection?.firstInspectionTime;
      data.InspectionCompletionDuration = data?.data?.inspection?.firstInspectionResponseTime;
      data.FirstInspectionEndTime = moment(data?.data?.inspection?.firstInspectionTime)
        .add(data?.data?.inspection?.firstInspectionResponseTime, "milliseconds")
        .toISOString();

      data.NumberOfNearMissObjects = data?.data?.nearmiss?.object;
      data.NumberOfNearMissPedestrians = data?.data?.nearmiss?.pedestrian;
      data.NumberOfLowImpacts = data?.data?.impact?.low;
      data.NumberOfMediumImpacts = data?.data?.impact?.medium;
      data.NumberOfHighImpacts = data?.data?.impact?.high;

      data.loginTimeFormatted = data?.loginTime
        ? `${moment(data?.loginTime).utcOffset(currentTimeZone.split(" ")[0]).format("MM/DD/YYYY, hh:mm A")} ${getTimeWithTimeZone(currentTimeZone)}`
        : "NA";

      const logOutTimeFormatted = data?.loginTime
        ? `${moment(data?.logOutTime).utcOffset(currentTimeZone.split(" ")[0]).format("MM/DD/YYYY, hh:mm A")} ${getTimeWithTimeZone(currentTimeZone)}`
        : "NA";

      data.logOutTimeFormatted = logOutTimeFormatted;

      const firstInspectionTimeFormatted =
        data?.firstInspectionTime && data?.firstInspectionTime !== "NA"
          ? moment.utc(data?.firstInspectionTime).local().format("MM/DD/YYYY, hh:mm A ") + timeZoneShort
          : "NA";

      data.firstInspectionTimeFormatted = firstInspectionTimeFormatted;

      const FirstInspectionEndTimeFormatted =
        data?.FirstInspectionEndTime && data?.FirstInspectionEndTime !== "NA"
          ? moment.utc(data?.FirstInspectionEndTime).local().format("MM/DD/YYYY, hh:mm A ") + timeZoneShort
          : "NA";

      data.FirstInspectionEndTimeFormatted = FirstInspectionEndTimeFormatted;

      return data;
    });
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const linkClickHandler = () => {
    setShowMenu(!showMenu);
  };

  const [showDownloadBulkLog, setShowDownloadBulkLog] = useState(false);
  const [downloadBulkLogsSuccess, setDownloadBulkLogsSuccess] = useState(false);
  const handleShowDownloadBulkLog = () => {
    setShowDownloadBulkLog(true);
    toggleMenu();
  };
  return (
    <>
      <CSVButtonWrapper>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Export CSV</Tooltip>}>
          <Button onClick={toggleMenu} iconOnly showIcon iconClass="csv-icon" buttonType="iconCSV" />
        </OverlayTrigger>

        {showMenu && (
          <CustomDropdown>
            <>
              <ClickWrapper>
                {!isDownloadAllSelected && (
                  <>
                    {downloadReportSet.size <= 0 ? (
                      <OptionText isDisabled={true}>
                        <Trans>Download Selected Logs menu option</Trans>
                      </OptionText>
                    ) : (
                      <CSVLink
                        data={generateLoginRecordsData()}
                        style={
                          downloadReportSet.size <= 0
                            ? {
                                textDecoration: "none",
                                pointerEvents: "none",
                                color: "#ccc",
                                opacity: 0.65,
                                border: 0,
                              }
                            : {
                                textDecoration: "none",
                              }
                        }
                        headers={csvHeadersData}
                        onClick={linkClickHandler}
                        filename={"login_records_" + new Date().toString() + ".csv"}
                      >
                        <Trans>Download Selected Logs menu option</Trans>
                      </CSVLink>
                    )}
                    <br />
                    <OptionText onClick={handleShowDownloadBulkLog}>
                      <Trans>Download Bulk Logs menu option</Trans>
                    </OptionText>
                  </>
                )}
              </ClickWrapper>
            </>
          </CustomDropdown>
        )}
      </CSVButtonWrapper>
      <SnackBar isError={isError} label={isError} handleClose={handleCloseSnackBar} />
      <DownloadAllReportModal
        showDownloadAllReportModal={downloadBulkLogsSuccess}
        setShowDownloadAllReportModal={setDownloadBulkLogsSuccess}
      />

      <ModalPopup
        show={showDownloadBulkLog}
        size="lg"
        centered
        backdrop="static"
        title="Download Bulk Logs"
        handleClose={() => {
          setShowDownloadBulkLog(false);
        }}
      >
        <DownloadBulkLogsFilters
          setShowDownloadBulkLog={setShowDownloadBulkLog}
          setDownloadBulkLogsSuccess={setDownloadBulkLogsSuccess}
        />
      </ModalPopup>
    </>
  );
};

export default ExportToCSV;
