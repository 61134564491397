/* eslint-disable jsx-a11y/anchor-is-valid */

//TODO- Clean up this file when time permits. Remove unused/commented code, improve/optimize code

import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import { updateActiveNavItem } from "../../../../../redux/sideMenu/menu.action";
import { updateMenu, updateMenuReport } from "../../../../../redux/sideMenu/menu.action";
import { getAllCount, getIncidentCount, getIncidentGraphData } from "../../../../../redux/counts/action";
import { toDownloadFiles } from "../../../../../redux/config/action";
import { setIncidentReportFilter } from "../../../../../redux/filters/action";
import { setDropdownAccountId, setDropdownWorksiteId } from "../../../../../redux/dropdownFilters/action";
import DropdownWrapper from "../../../../../components/DropdownComponent/DropdownWrapper";
import DateRangePicker from "../../../../../components/DatePicker.component";
import { getImpactReportListing } from "../../../../../redux/reports/action";
import QuickAccess from "../QuickManagement/QuickManagement.component";
import "react-multi-carousel/lib/styles.css";
import Button from "../../../../../components/Button/Button.component";
import DashboardGraph from "../../DashboardGraph";
import { apiCall } from "../../../../../utils/apiCall";
import SieraLoader from "../../../../../components/SieraLoader/SieraLoader.component";
import {
  DashboardContainer,
  CarouselDivCont,
  CarouselDivContto,
  HighMediumImpactDiv,
  GraphContainer,
} from "./IncidentDashboard.component.styles";
import PermissionsCheck from "../../../../../components/PermissionCheck/PermissionsCheck";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import DataTableIncident from "./IncidentHighMediumTableWrapper";
import DivContainer from "../../../../../components/DivContanerCompnent/DivContainer.component";
import MoreFilterMobileToggle from "../../../../../components/More Filter Button/MoreFilterButton.component";
import CarouselDataInspection from "./IncidentCarouselWrapper";
import { ThemeContext } from "styled-components";

function IncidentDashboard(props) {
  const [isLoading, setIsLoading] = useState(true);
  const {
    history,
    loginDetails,
    isSuperAdmin,
    isAccountAdmin,
    isAccountOwner,
    isSiteAdmin,
    isAccountViewer,
    getImpactReportListLoading,
    impactReportList,
    getImpactReportListing,
    getLastHourMeterListing,
    lastHourMeterReadingList,
    lastHourMeterReadingLoading,
    updateMenu,
    updateMenuReport,
    getAllCount,
    getIncidentCount,
    allCount,
    getAllCountLoading,
    incidentCount,
    setIncidentReportFilter,
    incidentReportFilter,
    dropdownWorksiteList,
    dropdownAccountList,
    setDropdownAccountId,
    dropdownAssetTypeList,
    setDropdownWorksiteId,
    dropdownAssetList,
    getGraphData,
    graphData,
    dropdownFilterWorksiteId,
    updateActiveNavItem,
    toDownloadFiles,
    fileDownloadLoading,
    permissions,
    isSingleAccountMultiSiteUser,
    getIncidentCountLoading,
    isIncidentGraphLoading,
  } = props;
  const { t } = useTranslation();
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [filterByTypeLabel, setFilterByTypeLabel] = useState("all");
  const [lastFilterData, setLastFilterData] = useState([]);
  const [currentActiveKey, setCurrentActiveKey] = useState("");
  const [managementCountBanner, setManagementCountBanner] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loadingReportId, setLoadingReportId] = useState("");
  const [ignoreFilter, setIgnoreFilter] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const themeContext = useContext(ThemeContext);

  const FilterByTypeLabelData = {
    asset: t("Asset"),
    assetType: t("Asset Type"),
    inspectedBy: t("Inspected By"),
    inspectionResult: t("Inspection Result"),
  };

  let searchedLastHourMeterReading =
    lastHourMeterReadingList &&
    lastHourMeterReadingList?.filter?.(
      (list) => list?.assetName?.toLowerCase?.()?.indexOf?.(searchText.toLowerCase()) !== -1
    );

  let { accountId, filterByType, endDate, startDate, worksiteId } = incidentReportFilter;

  let filterByTypeData = [
    { name: t("Asset"), _id: "asset" },
    { name: t("Asset Type"), _id: "assetType" },
    { name: t("User"), _id: "inspectedBy" },
    // { name: "Impact Severity", _id: "inspectionResult" },
  ];
  const onclickCarousel = (e, item, subSection) => {
    updateActiveNavItem(item?.toLowerCase());
    updateMenu({ section: "management", subSection: subSection });
  };

  useEffect(() => {
    const managementCountBannerLocal = {
      ACCOUNT_MANAGEMENT: {
        name: t("Accounts"),
        iconClass: "accounts",
        type: "ThemePrimary",
        feildName: "accounts",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Accounts", 0),
      },
      WORKSITE_MANAGEMENT: {
        name: t("Worksites"),
        iconClass: "worksites",
        type: "ThemePrimary",
        feildName: "sites",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Worksites", 1),
      },
      ASSET_MANAGEMENT: {
        name: t("Assets"),
        iconClass: "assets",
        type: "ThemePrimary",
        feildName: "assets",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Assets", 2),
      },
      USER_MANAGEMENT: {
        name: t("Users"),
        iconClass: "users",
        type: "ThemePrimary",
        feildName: "users",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Users", 3),
      },
    };

    const permissionsNameArr = [
      "ACCOUNT_MANAGEMENT",
      "WORKSITE_MANAGEMENT",
      "ASSET_MANAGEMENT",
      "USER_MANAGEMENT",
    ];

    const managementCountBannerUpdated = [];

    if (permissions?.[0]) {
      permissions?.[0]?.permissions?.forEach((permission) => {
        if (permission.name === "REPORTS" && permission.action.view) {
          setViewReport(true);
        }

        if (permissionsNameArr.includes(permission.name)) {
          if (!permission?.action?.view) {
            const bannerObj = managementCountBannerLocal[permission.name];
            delete bannerObj.to;
            delete bannerObj.onClick;
            managementCountBannerUpdated.push(managementCountBannerLocal[permission.name]);
          } else {
            managementCountBannerUpdated.push(managementCountBannerLocal[permission.name]);
          }
        }
      });
    }
    setManagementCountBanner(managementCountBannerUpdated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSuperAdmin) {
      setIncidentReportFilter({
        ...incidentReportFilter,
        accountId: loginDetails && loginDetails.accountId,
      });
    }
    setIsLoading(true);
    getAllCount();
    getIncidentCount(incidentReportFilter);
    getGraphData(incidentReportFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails]);

  useEffect(() => {
    if (!getAllCountLoading && !getIncidentCountLoading && !isIncidentGraphLoading && !fileDownloadLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [getAllCountLoading, getIncidentCountLoading, isIncidentGraphLoading, fileDownloadLoading]);

  useEffect(() => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      assetTypeId: "all",
      assetId: "all",
      inspectorId: "all",
      // impactSeverity: "highmedium",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentReportFilter.filterByType]);

  useEffect(() => {
    if (ignoreFilter) {
      return;
    }
    getImpactReportListing({
      ...incidentReportFilter,
      impactSeverity: "highmedium",
      pageSize: 10,
    });
    getIncidentCount({ ...incidentReportFilter });
    getGraphData(incidentReportFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentReportFilter]);

  const toogleDropdown = (eventKey) => {
    if (eventKey !== currentActiveKey) {
      setCurrentActiveKey(eventKey);
    } else {
      setCurrentActiveKey("");
    }
  };

  const handleViewAll = () => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      impactSeverity: "all",
      pageSize: 10,
    });
    updateMenuReport({ section: "report", subSection: 1 });
  };

  const changeLastFilter = (value) => {
    if (incidentReportFilter.filterByType === "asset") {
      setIncidentReportFilter({
        ...incidentReportFilter,
        assetId: value,
        pageNo: 1,
      });
    } else if (incidentReportFilter.filterByType === "assetType") {
      setIncidentReportFilter({
        ...incidentReportFilter,
        assetTypeId: value,
        pageNo: 1,
      });
    } else if (incidentReportFilter.filterByType === "inspectedBy") {
      setIncidentReportFilter({
        ...incidentReportFilter,
        inspectorId: value,
        pageNo: 1,
      });
    }
  };

  const changeFilterByType = (value) => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      filterByType: value,
      pageNo: 1,
    });
    setFilterByTypeLabel(FilterByTypeLabelData[value]);
    if (value === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (value === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    } else if (value === "inspectedBy") {
      // New backend api

      const url = `/dropdown/users?accid=${
        isSuperAdmin ? incidentReportFilter.accountId : loginDetails.accountId
      }&siteid=${
        isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownFilterWorksiteId
      }&status=true`;

      apiCall(url).then((resp) => {
        setLastFilterData(resp.data?.data?.data);
      });
    }
  };

  useEffect(() => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      worksiteId: "all",
      assetId: "all",
      assetTypeId: "all",
      inspectorId: "all",
      impactSeverity: "all",
      pageNo: 1,
    });
  }, [incidentReportFilter.accountId]);

  useEffect(() => {
    if (incidentReportFilter.filterByType === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (incidentReportFilter.filterByType === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    } else if (incidentReportFilter.filterByType === "inspectedBy") {
      apiCall(`/checklist/filter/inspector/${isSuperAdmin ? accountId : loginDetails.accountId}`).then(
        (resp) => {
          let newData = [];
          let nameSet = new Set();
          resp.data.data.forEach((data) => {
            if (nameSet.has(data.inspectorId && data.inspectorId.fullName)) {
              return;
            }
            nameSet.add(data.inspectorId && data.inspectorId.fullName);
            newData.push({
              _id: data.inspectorId && data.inspectorId._id,
              fullName: data.inspectorId && data.inspectorId.fullName,
            });
          });
          setLastFilterData(newData);
        }
      );
    }
  }, [dropdownAssetTypeList, dropdownAssetList]);

  const carouselData = [
    {
      name: t("Total Incidents"),
      iconClass: "total-incidents",
      type: "ThemePrimary",
      feildName: "totalImpacts",
      state: { impactSeverity: "all" },
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setIncidentReportFilter({
          ...incidentReportFilter,
          //...data.state,
        });
        updateActiveNavItem("incidentReports");
        updateMenuReport({ section: "report", subSection: 1 });
      },
    },
    {
      name: t("Near Miss Pedestrian"),
      iconClass: "near-miss-pedestrian",
      type: "RedPrimary",
      feildName: "nearMissPedestrian",
      state: { incidentType: "Proximity", obstacleType: "Pedestrian" },
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setIncidentReportFilter({
          ...incidentReportFilter,
          filterByType: "incidentType",
          incidentType: "Proximity",
          obstacleType: "Pedestrian",
          impactSeverity: "all",
          // ...data.state,
        });
        updateActiveNavItem("incidentReports");
        updateMenuReport({ section: "report", subSection: 1 });
      },
    },
    {
      name: t("Near Miss Object"),
      iconClass: "near-miss-object",
      type: "OrangePrimary",
      feildName: "nearMissObject",
      state: { incidentType: "Proximity", obstacleType: "Object" },
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setIncidentReportFilter({
          ...incidentReportFilter,
          filterByType: "incidentType",
          incidentType: "Proximity",
          obstacleType: "Object",
          impactSeverity: "all",
          // ...data.state,
        });
        updateActiveNavItem("incidentReports");
        updateMenuReport({ section: "report", subSection: 1 });
      },
    },
    {
      name: t("High Impacts"),
      iconClass: "incident-high",
      type: "RedPrimary",
      feildName: "HighImpacts",
      state: { impactSeverity: "high" },
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setIncidentReportFilter({
          ...incidentReportFilter,
          filterByType: "incidentType",
          impactSeverity: "high",
          incidentType: "Impact",
          // ...data.state,
        });
        updateActiveNavItem("incidentReports");
        updateMenuReport({ section: "report", subSection: 1 });
      },
    },
    {
      name: t("Medium Impacts"),
      iconClass: "incident-medium",
      type: "OrangePrimary",
      feildName: "MediumImpacts",
      state: { impactSeverity: "medium" },
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setIncidentReportFilter({
          ...incidentReportFilter,
          filterByType: "incidentType",
          impactSeverity: "medium",
          incidentType: "Impact",
          // ...data.state,
        });
        updateActiveNavItem("incidentReports");
        updateMenuReport({ section: "report", subSection: 1 });
      },
    },
    {
      name: t("Low Impacts"),
      iconClass: "incident-low",
      type: "GreenPrimary",
      feildName: "LowImpacts",
      state: { impactSeverity: "low" },
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setIncidentReportFilter({
          ...incidentReportFilter,
          filterByType: "incidentType",
          impactSeverity: "low",
          incidentType: "Impact",
          // ...data.state,
        });
        updateActiveNavItem("incidentReports");
        updateMenuReport({ section: "report", subSection: 1 });
      },
    },
  ];

  const carouselDataNoReportView = [
    {
      name: t("Total Incidents"),
      iconClass: "total-incidents",
      type: "ThemePrimary",
      feildName: "totalImpacts",
      state: { impactSeverity: "all" },
    },
    {
      name: t("Near Miss Pedestrian"),
      iconClass: "near-miss-pedestrian",
      type: "RedPrimary",
      feildName: "nearMissPedestrian",
      state: { incidentType: "Proximity", obstacleType: "Pedestrian" },
    },
    {
      name: t("Near Miss Object"),
      iconClass: "near-miss-object",
      type: "OrangePrimary",
      feildName: "nearMissObject",
      state: { incidentType: "Proximity", obstacleType: "Object" },
    },
    {
      name: t("High Impacts"),
      iconClass: "incident-high",
      type: "RedPrimary",
      feildName: "HighImpacts",
      state: { impactSeverity: "high" },
    },
    {
      name: t("Medium Impacts"),
      iconClass: "incident-medium",
      type: "OrangePrimary",
      feildName: "MediumImpacts",
      state: { impactSeverity: "medium" },
    },
    {
      name: t("Low Impacts"),
      iconClass: "incident-low",
      type: "GreenPrimary",
      feildName: "LowImpacts",
      state: { impactSeverity: "low" },
    },
  ];

  const viewReportClickHandler = (e, data) => {
    if (
      data?.impactSeverity?.toLowerCase?.() === "low" ||
      data?.impactSeverity?.toLowerCase?.() === "medium" ||
      data?.impactSeverity?.toLowerCase?.() === "high"
    ) {
      localStorage.setItem("impactReportObject", JSON.stringify(data));
      window.open("/#/app/impact-report", "_blank");
    } else {
      localStorage.setItem("nearMissReportObject", JSON.stringify(data));
      window.open("/#/app/near-miss-report", "_blank");
    }
  };

  useEffect(() => {
    if (!showMoreFilter) {
      setFilterByTypeLabel("all");
    }
  }, [showMoreFilter]);

  const nFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  };

  return (
    <DashboardContainer>
      {isLoading ? (
        <SieraLoader showLabelBelowLogo label="Getting Ready" />
      ) : (
        <>
          <MoreFilterMobileToggle>
            <>
              <Row>
                {isSuperAdmin && (
                  <Col lg={2}>
                    <DropdownWrapper
                      filter={{
                        type: t("Account"),
                        name: "accountName",
                        key: "companyName",
                        data: dropdownAccountList,
                      }}
                      value={accountId}
                      //  label={t("filter")}
                      handleFilter={(value) => {
                        setIncidentReportFilter({
                          ...incidentReportFilter,
                          accountId: value,
                        });
                        setDropdownAccountId(value);
                      }}
                    />
                  </Col>
                )}

                {!isSiteAdmin && (
                  <Col lg={2}>
                    <DropdownWrapper
                      filter={{
                        type: t("Worksite"),
                        name: "worksiteName",
                        key: "name",
                        data: dropdownWorksiteList,
                      }}
                      value={worksiteId}
                      // label={t("filter")}
                      handleFilter={(value) => {
                        setDropdownWorksiteId(value);
                        setIncidentReportFilter({
                          ...incidentReportFilter,
                          worksiteId: value,
                          assetId: null,
                          assetTypeId: null,
                        });
                      }}
                    />
                  </Col>
                )}

                <Col lg={4}>
                  <DateRangePicker
                    startDate={incidentReportFilter.startDate}
                    endDate={incidentReportFilter.endDate}
                    setDate={setIncidentReportFilter}
                    filterState={incidentReportFilter}
                  />
                </Col>
                <Col className="text-left" style={{ marginTop: "20px" }}>
                  <Button
                    iconClass="filter"
                    label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                    onClick={() => setShowMoreFilter(!showMoreFilter)}
                    showIcon
                    buttonType={showMoreFilter ? "primary" : "secondary"}
                  />
                </Col>
              </Row>
              {showMoreFilter && (
                <Row>
                  <Col lg={2}>
                    <DropdownWrapper
                      filter={{
                        type: t("Filter By"),
                        name: "filterBy",
                        key: "name",
                        placeholder: t("Select a Filter"),
                        data: filterByTypeData,
                      }}
                      value={incidentReportFilter.filterByType}
                      // label={t("filter")}
                      handleFilter={(value) => changeFilterByType(value)}
                      useAsDropdown={true}
                    />
                  </Col>
                  {filterByTypeLabel !== "all" && (
                    <Col lg={2}>
                      <DropdownWrapper
                        filter={{
                          type: `${filterByTypeLabel === t("Inspected By") ? t("User") : filterByTypeLabel}`,
                          key:
                            filterByType === "asset"
                              ? "assetName"
                              : filterByType === "assetType"
                                ? "AssetTypeName"
                                : "fullName",
                          data: lastFilterData,
                        }}
                        value={
                          incidentReportFilter[
                            filterByType === "asset"
                              ? "assetId"
                              : filterByType === "assetType"
                                ? "assetTypeId"
                                : "inspectorId"
                          ]
                        }
                        useAsDropdown={false}
                        label={t("filter")}
                        handleFilter={(e) => changeLastFilter(e)}
                        disabled={
                          filterByType === "assetType" &&
                          isSuperAdmin &&
                          (incidentReportFilter.accountId === "all" || !incidentReportFilter.accountId)
                        }
                      />
                    </Col>
                  )}
                </Row>
              )}
            </>
          </MoreFilterMobileToggle>

          <Row className="mt-2">
            <Col lg={8}>
              <CarouselDivCont>
                <CarouselDataInspection
                  viewReport={viewReport}
                  incidentCount={incidentCount}
                  updateActiveNavItem={updateActiveNavItem}
                  setIncidentReportFilter={setIncidentReportFilter}
                  incidentReportFilter={incidentReportFilter}
                  carouselData={carouselData}
                  carouselDataNoReportView={carouselDataNoReportView}
                />
              </CarouselDivCont>
            </Col>
            <Col lg={4}>
              <CarouselDivContto>
                <CarouselDataInspection
                  viewReport={false}
                  allCount={allCount}
                  managementCountBanner={managementCountBanner}
                  size="small"
                />
              </CarouselDivContto>
            </Col>
          </Row>
          <Row>
            <Col lg={isAccountViewer ? 12 : 8}>
              <GraphContainer>
                <DashboardGraph
                  title={t("Incident Trends")}
                  series={[
                    {
                      name: t("Total Incidents"),
                      data: graphData?.count ?? [],
                    },
                    {
                      name: t("High Impacts"),
                      data: graphData?.high ?? [],
                    },
                    {
                      name: t("Medium Impacts"),
                      data: graphData?.medium ?? [],
                    },
                    {
                      name: t("Low Impacts"),
                      data: graphData?.low ?? [],
                    },
                  ]}
                  colors={[
                    themeContext?.colors?.themePrimary || "#0D5FBE",
                    themeContext?.colors?.redPrimary || "#DD0000",
                    themeContext?.colors?.orangePrimary || "#DD9000",
                    themeContext?.colors?.greenPrimary || "#11A70B",
                  ]}
                  date={graphData?.date}
                  startDate={incidentReportFilter.startDate}
                  endDate={incidentReportFilter.endDate}
                />
              </GraphContainer>

              <PermissionCheck section={"DASHBOARD"} permissionName={"REPORTS"} actionName={"view"}>
                <HighMediumImpactDiv>
                  <DivContainer
                    heading={t("High and Medium Impact Incidents")}
                    to="/app/report"
                    onClick={() => handleViewAll()}
                  >
                    <DataTableIncident
                      data={impactReportList}
                      isSiteAdmin={isSiteAdmin}
                      isSingleAccountMultiSiteUser={isSingleAccountMultiSiteUser}
                      loading={getImpactReportListLoading}
                    />
                  </DivContainer>
                </HighMediumImpactDiv>
              </PermissionCheck>
            </Col>
            <PermissionsCheck
              section="DASHBOARD"
              permissionNames={[
                "ASSET_MANAGEMENT",
                "ASSET_TYPE_MANAGEMENT",
                "WORKSITE_MANAGEMENT",
                "USER_MANAGEMENT",
              ]}
              actionName="add"
              operator="OR"
            >
              <Col lg={isAccountViewer ? 12 : 4} className="mt-2">
                <DivContainer heading={t("Quick Management Access")} showViewAll={false}>
                  <QuickAccess />
                </DivContainer>
                {/* Below code is commented as part of SIER-3209 temporarily*/}
                {/* <HourMeterReading
            getLastHourMeterListing={getLastHourMeterListing}
            lastHourMeterReadingLoading={lastHourMeterReadingLoading}
            reportFilter={incidentReportFilter}
            lastHourMeterReadingList={lastHourMeterReadingList}
          /> */}
              </Col>
            </PermissionsCheck>
          </Row>
        </>
      )}
    </DashboardContainer>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  isSiteAdmin: state.user.isSiteAdmin,
  isAccountViewer: state.user.isAccountViewer,
  menuList: state.sideMenu.reportMenuState,
  getImpactReportListLoading: state.reports.getImpactReportListLoading,
  impactReportList: state.reports.impactReportList,
  worksiteList: state.worksites.Worksites,
  accountList: state.accounts.Accounts,
  assetsList: state.assets.Assets,
  assetTypeList: state.assets.AssetTypes,
  lastHourMeterReadingList: state.reports.lastHourMeterReadingList,
  lastHourMeterReadingLoading: state.reports.getLastHourMeterListLoading,
  allCount: state.counts.allCount,
  getAllCountLoading: state.counts.getAllCountLoading,
  getIncidentCountLoading: state.counts.getIncidentCountLoading,
  incidentCount: state.counts.incidentCount,
  incidentReportFilter: state.filters.incidentReportFilter,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownAssetList: state.dropdownFilters.assetList,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  graphData: state.counts.incidentGraphData,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  fileDownloadLoading: state.config.fileDownloadLoading,
  permissions: state.user?.loginDetails?.userData?.permissions,
  isSingleAccountMultiSiteUser: state.user.isSingleAccountMultiSiteUser,
  isIncidentGraphLoading: state.user.isIncidentGraphLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenu: (menuState) => dispatch(updateMenu(menuState)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  getImpactReportListing: (filterData) => dispatch(getImpactReportListing(filterData)),
  getGraphData: (filterData) => dispatch(getIncidentGraphData(filterData)),
  updateMenuReport: (menuDetails) => dispatch(updateMenuReport(menuDetails)),
  getAllCount: () => dispatch(getAllCount()),
  getIncidentCount: (filterData) => dispatch(getIncidentCount(filterData)),
  setIncidentReportFilter: (filterData) => dispatch(setIncidentReportFilter(filterData)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (worksiteId) => dispatch(setDropdownWorksiteId(worksiteId)),
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IncidentDashboard));
