import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import {
  RefreshButton,
  RefreshButtonDiv,
  CSVButtonWrapper,
  PDFCSVREFRESHButton,
  PDFButtonWrapper,
  RefreshButtonD,
} from "./../../Reports.styles";

// Button Component
import Button from "../../../../../components/Button/Button.component";

/* import { CSVDownloadIcon } from "../../../../../assets";
import { PDFDownloadIcon } from "../../../../../assets"; */
const SaveToOptions = ({
  getZipDownloadReport,
  downloadReportSet,
  isDownloadAllSelected,
  getZipFileReportLoading,
  exportAllInspectionCSVLoading,
  exportAllInspectionCSV,
  inspectionReportList,
  refreshData,
  /* dropdownWorksiteList, */
}) => {
  const { t } = useTranslation();
  const csvHeadersData = [
    { label: t("Worksite"), key: "worksite" },
    { label: t("Asset Name"), key: "assetName" },
    { label: t("Asset Type"), key: "assetType" },
    { label: t("Inspection Result"), key: "lastInspectionResult" },
    { label: t("Inspected By"), key: "lastInspectedBy" },
    { label: t("Inspected On"), key: "lastInspectedOn" },
    { label: t("Failed Reason"), key: "inspectionFailReason" },
    { label: t("Notes"), key: "inspectionNotes" },
  ];

  const handleReportZipDownload = () => {
    if (downloadReportSet.size <= 0) {
      return;
    }
    let reportArray = Array.from(downloadReportSet);
    getZipDownloadReport(reportArray);
  };

  const handleDownloadAllReport = () => {
    exportAllInspectionCSV();
  };

  const generateCsv = (reportList) => {
    let newCsv = [];
    reportList.map((data) => {
      if (downloadReportSet && !downloadReportSet.has(data?._id)) {
        return;
      }
      let newRow = {};
      newRow.worksite = data?.siteId && data?.siteId?.name;
      newRow.assetName = data?.assetId && data?.assetId?.assetName;
      newRow.assetType =
        data?.assetId && data?.assetId?.assetTypeId && data?.assetId?.assetTypeId?.AssetTypeName;
      newRow.lastInspectionResult =
        data?.status == "passed" ? "PASS" : data?.status == "failed" ? "FAIL" : "TIMED OUT";
      newRow.lastInspectedBy = data?.inspectorId && data?.inspectorId?.fullName;
      newRow.lastInspectedOn = moment(data?.inspectionAt)
        .utcOffset(data?.siteId?.timeZone.split(" ")[0])
        .format("MMM-DD-YYYY hh:mm A");

      if (data?.inspectionFailReason?.length > 0) {
        let inspectionFailedReason = "";
        data.inspectionFailReason.map((inspectionFailedReasondata, i) => {
          inspectionFailedReason =
            inspectionFailedReason + (i === 0 ? "" : "|") + inspectionFailedReasondata?.question;
        });
        newRow.inspectionFailReason = inspectionFailedReason;
      }

      if (data?.response?.length > 0) {
        let inspectionNotes = "";
        data.response.map((section, i) => {
          section?.questions?.map((question, i) => {
            if (question?.note) {
              inspectionNotes = inspectionNotes + (i === 0 ? "" : "|") + question?.note;
            }
          });
        });
        newRow.inspectionNotes = inspectionNotes;
      }

      newCsv.push(newRow);
    });
    return newCsv;
  };

  const [rotation, setRotation] = useState(0);
  const [isRotating, setIsRotating] = useState(false);

  const onRefresh = () => {
    setIsRotating(true);

    setTimeout(() => {
      setIsRotating(false);
      setRotation(rotation + 360);
    }, 2000);
  };

  return (
    <PDFCSVREFRESHButton>
      <PDFButtonWrapper disabled={isDownloadAllSelected || downloadReportSet.size <= 0}>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <Trans>PDF_DOWNLOAD</Trans>
            </Tooltip>
          }
        >
          <Button
            onClick={() => {
              handleReportZipDownload();
            }}
            iconOnly
            label={
              getZipFileReportLoading && (
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              )
            }
            disabled={isDownloadAllSelected || downloadReportSet.size === 0}
            showIcon
            iconClass="pdf-icon"
            buttonType="iconPDF"
          />
        </OverlayTrigger>
      </PDFButtonWrapper>

      <CSVButtonWrapper disabled={downloadReportSet.size <= 0}>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <Trans>CSV_DOWNLOAD</Trans>
            </Tooltip>
          }
        >
          {isDownloadAllSelected ? (
            <>
              {exportAllInspectionCSVLoading ? (
                <>
                  <Button
                    iconOnly
                    label={
                      <div class="mr-1 spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    }
                    showIcon
                    iconClass="csv-icon"
                    buttonType="iconCSV"
                  />
                </>
              ) : (
                <>
                  <Button
                    onClick={() => handleDownloadAllReport()}
                    iconOnly
                    showIcon
                    iconClass="csv-icon"
                    buttonType="iconCSV"
                  />
                </>
              )}
            </>
          ) : (
            <CSVLink
              data={downloadReportSet.size <= 0 ? "" : generateCsv(inspectionReportList)}
              // className={`${downloadReportSet.size <= 0 ? "CSVicon_disabled" : "CSVicon_enabled"}`}
              headers={csvHeadersData}
              onClick={true}
              filename={"report.csv"}
            >
              <Button
                iconOnly
                disabled={downloadReportSet.size == 0}
                showIcon
                iconClass="csv-icon"
                buttonType="iconCSV"
              />
            </CSVLink>
          )}
        </OverlayTrigger>
      </CSVButtonWrapper>
      <RefreshButtonDiv>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <Trans>Refresh</Trans>
            </Tooltip>
          }
        >
          <RefreshButton onClick={refreshData}>
            <RefreshButtonD
              rotating={isRotating}
              onClick={onRefresh}
              style={{ transform: `rotate(${rotation}deg)` }}
              className="icon icon-update"
            ></RefreshButtonD>
          </RefreshButton>
        </OverlayTrigger>
      </RefreshButtonDiv>
    </PDFCSVREFRESHButton>
  );
};

export default SaveToOptions;
