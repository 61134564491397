import React, { useState } from "react";
import DataTableInspection from "../../../../../components/DataTableComponent/DataTable.component";
import { tableHeader, rowPerPageDropdown } from "../constants";
import { useTranslation, Trans } from "react-i18next";
import { toDownloadFiles } from "../../../../../redux/config/action";
import awsConstant from "../../../../../constant/awsFolder.json";
import PopoverCustom from "../../../../../components/PopoverCustom.component";
import ReportDownload from "../../ReportShare/ReportShareModal.component";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Checkbox from "../../../../../components/Checkbox/Checkbox.component";
import moment from "moment-timezone";
import { getTimeWithTimeZone } from "../../../../../utils/timezoneConvert";

import {
  IncidentTypeDiv,
  DivStyled,
  DivSpanText,
  IconClass,
  StyledSpan,
} from "./TableWrapper.component.styles";

const InspectionReportsDataTable = ({
  data,
  loginDetails,
  isSuperAdmin,
  isSiteAdmin,
  toDownloadFiles,
  setIsSuccess,
  setIsError,
  fileDownloadLoading,
  handleCheckboxClick,
  handleSelectAll,
  downloadReportSet,
  isAllSelected,
  selectedAccount,
  handlePageLength,
  totalPageReport,
  previousPage,
  nextPage,
  pageSize,
  pageNo,
  totalInspectionReport,
  rowHeight,
  currentTimeZone,
  loading,
}) => {
  const [loadingReportId, setLoadingReportId] = useState("");
  const { t } = useTranslation();
  const downloadReportApiCall = (id) => {
    setLoadingReportId(id);
    toDownloadFiles(awsConstant.impactReport, `${id}.pdf`);
  };

  const viewReportClickHandler = (e, row) => {
    if (
      row?.impactSeverity?.toLowerCase?.() === "low" ||
      row?.impactSeverity?.toLowerCase?.() === "medium" ||
      row?.impactSeverity?.toLowerCase?.() === "high"
    ) {
      localStorage.setItem("impactReportObject", JSON.stringify(row));
      window.open("/#/app/impact-report", "_blank");
    } else {
      localStorage.setItem("nearMissReportObject", JSON.stringify(row));
      window.open("/#/app/near-miss-report", "_blank");
    }
  };

  const userData = {
    token: loginDetails?.token,
    sharedByUserName: loginDetails?.userData?.fullName,
  };

  const ActionCell = ({ row }) => {
    return (
      <>
        {fileDownloadLoading && row._id === loadingReportId ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <div className="action-div">
            <i title="View" onClick={(e) => viewReportClickHandler(e, row)} className="icon icon-view"></i>
            <i
              title="Download"
              onClick={() => downloadReportApiCall(row._id)}
              className="icon icon-download"
            ></i>
            {/* <i className="icon icon-share"></i> */}
            <i title="Share">
              <span style={{ display: "block" }}>
                <PopoverCustom
                  children={
                    <ReportDownload
                      userData={userData}
                      reportDetails={{
                        accountId: data?.accountId,
                        accountName: data?.accountId?.companyName,
                        reportType: 2,
                        reportId: row?._id,
                      }}
                      setIsSuccess={setIsSuccess}
                      setIsError={setIsError}
                    />
                  }
                />
              </span>
            </i>
          </div>
        )}
      </>
    );
  };

  const columnsWithCheckboxes = [
    {
      name: <Checkbox type="checkbox" onClick={(e) => handleSelectAll(e)} checked={isAllSelected} />,
      width: 34,
      selector: (row) => (
        <Checkbox
          onClick={(e) => handleCheckboxClick(e)}
          value={row._id}
          checked={downloadReportSet.has(row._id)}
        />
      ),
    },
  ];

  const columns = tableHeader
    .filter((data) => {
      if ((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite")) {
        return false;
      }
      return true;
    })

    .map((data) => {
      if (data.name === "Account") {
        return {
          name: t(data.name),
          selector: (row) => row?.companyName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Worksite") {
        return {
          name: t(data.name),
          selector: (row) => row?.siteName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Asset Type") {
        return {
          name: t(data.name),
          selector: (row) => row?.AssetTypeName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Incident By") {
        return {
          name: t(data.name),
          selector: (row) => row?.fullName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Asset Name") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Actions") {
        return {
          name: t(data.name),
          centered: true,
          selector: (row) => <ActionCell row={row} />,
          width: 120,
        };
      }

      if (data.name === "Date, Time") {
        return {
          name: t(data.name),
          selector: (row) => {
            const isStatus = row.hasOwnProperty("status");
            const isDate = row.hasOwnProperty("impactRecordedAt") && typeof row.impactRecordedAt === "string";
            const feildName = "impactRecordedAt";
            const timeZone = currentTimeZone || row?.siteId?.timeZone || "NA";

            if (!isStatus && !isDate) {
              return row[feildName] || row.email || "NA";
            } else if (isDate) {
              return `${moment(row[feildName])
                .utcOffset(timeZone?.split?.(" ")[0])
                .format("MMM-DD-YYYY, hh:mm A")} ${getTimeWithTimeZone(timeZone)}`;
            } else {
              return "NA";
            }
          },
          sortable: true,
        };
      }

      if (data.name === "Incident Type") {
        return {
          name: t(data.name),
          selector: (row) => {
            const tableData = {
              feildName: "impactSeverity",
              feildName2: "incidentObject",
            };

            const impactSeverity = row[tableData.feildName]?.toLowerCase();
            const incidentObject = row[tableData.feildName2]?.toLowerCase();

            const renderButton = () => {
              let buttonContent;
              let buttonClassName = "btn ImpactButton";
              let iconClassName = "icon";
              let spanTextContent = "";

              if (impactSeverity === "high") {
                buttonClassName += " btn-border-High anim-button";
                iconClassName += " icon-impact iconslide highColor";
                spanTextContent = "Impact ";
                buttonContent = row["impactG"] ? `${row["impactG"]}g` : "-";
              } else if (impactSeverity === "medium") {
                buttonClassName += " btn-border-Mid anim-button";
                iconClassName += " icon-impact iconslide midColor";
                spanTextContent = "Impact ";
                buttonContent = row["impactG"] ? `${row["impactG"]}g` : "-";
              } else if (impactSeverity === "low") {
                buttonClassName += " btn-border-Low anim-button";
                iconClassName += " icon-impact iconslide lowColor";
                spanTextContent = "Impact ";
                buttonContent = row["impactG"] ? `${row["impactG"]}g` : "-";
              } else if (incidentObject === "object") {
                buttonClassName += " btn-border-Mid anim-button";
                iconClassName += " icon-assets iconslide midColor";
                spanTextContent = "Near-Miss ";
                buttonContent = getButtonContent(row, loginDetails);
              } else if (incidentObject === "pedestrian") {
                buttonClassName += " btn-border-High anim-button";
                iconClassName += " icon-pedestrian iconslide highColor";
                spanTextContent = "Near-Miss ";
                buttonContent = getButtonContent(row, loginDetails);
              } else {
                // Content for Unauthorized
                return (
                  <button className="btn ImpactButton btn-border-High" style={{ borderColor: "#000000" }}>
                    {/* Content for Unauthorized */}
                  </button>
                );
              }

              return (
                <IncidentTypeDiv title={spanTextContent}>
                  <DivStyled className={buttonClassName}>
                    <DivSpanText className="text-a">{spanTextContent}</DivSpanText>
                    <IconClass className={iconClassName} />
                    <span className="text-b">{buttonContent}</span>
                  </DivStyled>
                </IncidentTypeDiv>
              );
            };

            const getButtonContent = (rowData, loginDetails) => {
              if (rowData?.incidentDetails?.length > 0) {
                const distance =
                  loginDetails?.unitType?.toLowerCase() === "imperial"
                    ? Math.floor(
                        Math.min(...rowData?.incidentDetails?.map((item) => item?.distanceinmm)) / 305
                      )
                    : Math.floor(Math.min(...rowData?.incidentDetails?.map((item) => item?.distanceinmm)));

                return loginDetails?.unitType?.toLowerCase() === "imperial"
                  ? `${distance}ft`
                  : `${distance}mm`;
              } else {
                const distance =
                  loginDetails?.unitType?.toLowerCase() === "imperial"
                    ? Math.floor(rowData?.distanceinmm / 305)
                    : Math.floor(rowData?.distanceinmm);

                return loginDetails?.unitType?.toLowerCase() === "imperial"
                  ? `${distance}ft`
                  : `${distance}mm`;
              }
            };

            return renderButton();
          },
          sortable: true,
        };
      }

      return {
        name: data.name,
        selector: () => data.feildName,
        sortable: true,
      };
    });

  columns.unshift(...columnsWithCheckboxes);

  const mobileCheckboxDateTime = [
    { columnName: "Account", className: "accountStyle" },
    { columnName: "Worksite", className: "worksiteStyle" },
    { columnName: "Asset Type", className: "assetTyleStyle" },
    { columnName: "Asset Name", className: "assetNameStyle" },
    { columnName: "Incident By", className: "inspectedByStyle" },
    { columnName: "Report Status", className: "reportStatusStyle" },
  ];

  const mobileCheckboxAccordian = [
    "Account",
    "Worksite",
    "Asset Type",
    "Asset Name",
    "Incident By",
    "Actions",
  ];

  if (!Array.isArray(data)) {
    return (
      <StyledSpan height={rowHeight}>
        <Trans>No_IMPACT_REPORT_AVAILABLE</Trans>
      </StyledSpan>
    );
  }

  return (
    <>
      {!selectedAccount && isSuperAdmin ? (
        <StyledSpan height={rowHeight}>
          <Trans>PLEASE_SELECT_AN_ACCOUNT_TO_VIEW_THE_REPORT</Trans>
        </StyledSpan>
      ) : (
        <DataTableInspection
          title=""
          columns={columns}
          data={data}
          pagination
          responsive
          dense
          rowsPerPageOptions={[10, 25, 50, 100]}
          loading={loading}
          rowHeight={"370"}
          scrollable={true}
          fixedHeader
          mobileCheckboxOnly
          mobileDateTimeOnly
          mobileActionItemOnly
          mobileCheckboxDateTime={mobileCheckboxDateTime}
          mobileCheckboxAccordian={mobileCheckboxAccordian}
          noDataMessage={t("No_IMPACT_REPORT_AVAILABLE")}
          pageSize={pageSize}
          pageNo={pageNo}
          totalPageReport={totalInspectionReport}
          handlePageLength={handlePageLength}
          paginationOptions={rowPerPageDropdown}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  fileDownloadLoading: state.config.fileDownloadLoading,
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  totalInspectionReport: state.reports.impactReportTotalCount,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InspectionReportsDataTable));
