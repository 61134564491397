import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";

import MoreFilterMobileToggle from "../../../../components/More Filter Button/MoreFilterButton.component";
import DropdownComponent from "../../../../components/DropdownComponent/Dropdown.component";
import DateRangePicker from "../../../../components/DatePicker.component";
import Button from "../../../../components/Button/Button.component";
import { getDropdownAccountList } from "../../../../redux/dropdownFilters/action";
import {
  getDropdownWorksiteList,
  getDropdownAssetList,
  getUsers,
} from "../../../../redux/modalDropdownFilters/action";
import { apiCall } from "../../../../utils/apiCall";
import { LoginRecordsStyle } from "./Filters.styles";
import ExportToCSV from "./ExportToCSV.component";

const Filters = ({
  assetList,
  dropdownAccountList,
  getDropdownWorksiteList,
  worksiteList,
  getDropdownAssetList,
  getUsers,
  userList,
  setLoginRecords,
  pageSize,
  pageNo,
  downloadReportSet,
  recordSet,
  getWorksiteListLoading,
  getUserListLoading,
  getAssetListLoading,
  getDropdownAccountList,
  accountListLoading,
  currentTimeZone,
}) => {
  const { t } = useTranslation();
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [filterBy, setFilterBy] = useState({ value: "assets", label: "Assets" });
  const [selectedAccount, setSelectedAccount] = useState({ value: "all", label: "All Accounts" });
  const [accountListWithAll, setAccountListWithAll] = useState([]);
  const [worksitesListWithAll, setWorksitesListWithAll] = useState([]);
  const [selectedWorksite, setSelectedWorksite] = useState({ value: "all", label: "All Worksites" });
  const [selectedAsset, setSelectedAsset] = useState({ value: "all", label: "All Assets" });
  const [selectedUser, setSelectedUser] = useState({ value: "all", label: "All Users" });
  const [assetListWithAll, setAssetListWithAll] = useState([]);
  const [userListWithAll, setUserListWithAll] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(7, "days").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  const getLoginRecords = useCallback(async () => {
    const resp = await apiCall("/loginRecords/getLoginRecords", {
      method: "POST",
      data: {
        accountId: selectedAccount?.value,
        siteId: selectedWorksite?.value,
        assetTypeId: "all",
        assetId: selectedAsset?.value,
        userId: selectedUser?.value,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        pageNo: pageNo || 1,
        pageSize: pageSize || 10,
      },
    });

    if (resp.data.success) {
      setLoginRecords(resp.data);
    }
  }, [
    selectedAccount?.value,
    selectedWorksite?.value,
    selectedAsset?.value,
    selectedUser?.value,
    startDate,
    endDate,
    pageNo,
    pageSize,
    setLoginRecords,
  ]);

  const onDateChange = (date) => {
    setStartDate(date.startDate);
    setEndDate(date.endDate);
  };

  useEffect(() => {
    getLoginRecords();
  }, [
    selectedAccount?.value,
    selectedWorksite?.value,
    startDate,
    endDate,
    selectedUser?.value,
    selectedAsset?.value,
    pageNo,
    pageSize,
    getLoginRecords,
  ]);

  useState(() => {
    getDropdownAccountList();
  }, [getDropdownAccountList]);

  useEffect(() => {
    if (selectedAccount) {
      getDropdownWorksiteList(selectedAccount.value);
      getUsers(selectedAccount?.value, selectedWorksite?.value, null, 1, "all", "");
    }
  }, [selectedAccount, getDropdownWorksiteList, getUsers, selectedWorksite?.value]);

  useEffect(() => {
    if (selectedWorksite?.value) {
      getDropdownAssetList(selectedAccount?.value, selectedWorksite?.value, null);
    }
  }, [selectedWorksite, getDropdownAssetList, selectedAccount?.value]);

  useEffect(() => {
    if (dropdownAccountList?.length > 0 && dropdownAccountList?.[0]?._id !== -1) {
      const optionListTemp = dropdownAccountList.map((option) => ({
        value: option._id,
        label: option.companyName,
      }));
      optionListTemp.unshift({ value: "all", label: "All Accounts" });
      setAccountListWithAll(optionListTemp);
    } else {
      setAccountListWithAll([{ value: "all", label: "All Accounts" }]);
    }
  }, [dropdownAccountList]);

  useEffect(() => {
    if (worksiteList?.length > 0 && worksiteList?.[0]?._id !== -1) {
      const optionListTemp = worksiteList.map((option) => ({
        label: option.name,
        value: option._id,
      }));
      optionListTemp.unshift({ value: "all", label: "All Worksites" });
      setWorksitesListWithAll(optionListTemp);
    } else {
      setWorksitesListWithAll([{ value: "all", label: "All Worksites" }]);
    }
  }, [worksiteList]);

  useEffect(() => {
    if (assetList?.length > 0 && assetList?.[0]?._id !== -1) {
      const optionListTemp = assetList.map((option) => ({
        label: option.assetName,
        value: option._id,
      }));

      optionListTemp.unshift({ value: "all", label: "All Assets" });
      setAssetListWithAll(optionListTemp);
    } else {
      setAssetListWithAll([{ value: "all", label: "All Assets" }]);
    }
  }, [assetList]);

  useEffect(() => {
    if (userList?.length > 0 && userList?.[0]?._id !== -1) {
      const optionListTemp = userList.map((option) => ({
        label: option.fullName,
        value: option._id,
      }));

      optionListTemp.unshift({ value: "all", label: "All Users" });
      setUserListWithAll(optionListTemp);
    } else {
      setUserListWithAll([{ value: "all", label: "All Users" }]);
    }
  }, [userList]);

  return (
    <MoreFilterMobileToggle>
      <LoginRecordsStyle>
        <Row>
          <Col lg={2}>
            <DropdownComponent
              label={t("Account")}
              placeholder={"Select an Account"}
              options={accountListWithAll}
              value={selectedAccount}
              handleFilter={(selectedData) => {
                setSelectedAccount(selectedData);
              }}
              size="medium"
              isLoading={accountListLoading}
            />
          </Col>
          <Col md={2} className="mb-2">
            <DropdownComponent
              label={"Worksite"}
              placeholder={"Select a worksite"}
              options={worksitesListWithAll}
              value={selectedWorksite}
              handleFilter={(selectedWorksite) => {
                setSelectedWorksite(selectedWorksite);
              }}
              errorMessage={t("This field is required")}
              size="medium"
              isLoading={getWorksiteListLoading}
            />
          </Col>
          <Col lg={4}>
            <DateRangePicker
              setDate={(date) => onDateChange(date)}
              startDate={startDate}
              endDate={endDate}
              minDate={moment().subtract(730, "days").toDate()}
            />
          </Col>
          <Col className="text-left" style={{ marginTop: "20px" }}>
            <Button
              iconClass="filter"
              label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
              onClick={() => setShowMoreFilter(!showMoreFilter)}
              showIcon
              buttonType={showMoreFilter ? "primary" : "secondary"}
            />
          </Col>
          <Col>
            <ExportToCSV
              downloadReportSet={downloadReportSet}
              reportList={recordSet}
              currentTimeZone={currentTimeZone}
              // isDownloadAllSelected={isDownloadAllSelected}
              // loginDetails={loginDetails
              // date={date}
            />
          </Col>
        </Row>
        {showMoreFilter && (
          <Row>
            <Col lg={2}>
              <DropdownComponent
                label={"Filter By"}
                placeholder={"Select a Type"}
                options={[
                  {
                    value: "assets",
                    label: "Assets",
                  },
                  {
                    value: "users",
                    label: "Users",
                  },
                ]}
                value={filterBy}
                handleFilter={(option) => {
                  setFilterBy(option);
                }}
                size="medium"
              />
            </Col>
            {filterBy?.value === "users" && (
              <Col lg={2}>
                <DropdownComponent
                  label={"Users"}
                  placeholder={"Select a User"}
                  options={userListWithAll}
                  value={selectedUser}
                  handleFilter={(selectedUser) => {
                    setSelectedUser(selectedUser);
                    setSelectedAsset({ value: "all", label: "All Assets" });
                  }}
                  size="medium"
                  isLoading={getUserListLoading}
                />
              </Col>
            )}

            {filterBy?.value === "assets" && (
              <Col lg={2}>
                <DropdownComponent
                  label={"Assets"}
                  placeholder={"Select an Asset"}
                  options={assetListWithAll}
                  value={selectedAsset}
                  handleFilter={(selectedData) => {
                    setSelectedAsset(selectedData);
                    setSelectedUser({ value: "all", label: "All Users" });
                  }}
                  size="medium"
                  isLoading={getAssetListLoading}
                />
              </Col>
            )}
          </Row>
        )}
      </LoginRecordsStyle>
    </MoreFilterMobileToggle>
  );
};

const mapStateToProps = (state) => ({
  accountList: state.accounts.Accounts,
  dropdownAccountList: state.dropdownFilters.accountList,
  userList: state.modalDropdownFilters.userList,
  superAdminList: state.modalDropdownFilters.superAdminList,
  assetList: state.modalDropdownFilters.assetList,
  worksiteList: state.modalDropdownFilters.worksiteList,
  getWorksiteListLoading: state.modalDropdownFilters.getWorksiteListLoading,
  getUserListLoading: state.modalDropdownFilters.getUserListLoading,
  getAssetListLoading: state.modalDropdownFilters.getAssetListLoading,
  accountListLoading: state.dropdownFilters.accountListLoading,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (accountId, siteId, dropdownUserAccessLevel, pageNo, pageSize, searchKey) =>
    dispatch(getUsers(accountId, siteId, dropdownUserAccessLevel, pageNo, pageSize, searchKey)),
  getDropdownWorksiteList: (accountId) => dispatch(getDropdownWorksiteList(accountId)),
  getDropdownAssetList: (accountId, worksiteId, assetTypeId) =>
    dispatch(getDropdownAssetList(accountId, worksiteId, assetTypeId)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filters));
