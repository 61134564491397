import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";

import SwitchComponent from "../../../../../components/Switchbox/SwitchComponent";
import {
  OnOffText,
  ToggleWrapper,
  RowDiv,
  Column,
  RowsContainer,
  SettingTableDescription,
  CustomeUserDiv,
} from "../Notifications.styles";
import { getSystemSettingsUserTypes } from "../../../../../redux/notification/action";

import SettingsEmailSelector from "../../../../../components/SettingsSection/SettingsEmailSelector.component";

const Recipients = (props) => {
  const {
    notificationSetting,
    getSystemSettingsUserTypes,
    recipients,
    customEmails,
    handleCustomEmailsChange,
    handleRecipientsChange,
    isSystemSettingsEditable,
  } = props;

  const [localNotificationSettings, setLocalNotificationSettings] = useState(notificationSetting || []);

  useEffect(() => {
    setLocalNotificationSettings(notificationSetting);
  }, [notificationSetting]);

  useEffect(() => {
    getSystemSettingsUserTypes();
  }, []);

  const isTheUserTypeEnabled = (usertypeId) => {
    return recipients ? recipients?.includes(usertypeId) : false;
  };

  return (
    <SettingTableDescription>
      <>
        <RowDiv isHeader={true}>
          <Column even={true} isHeader={true}>
            <Trans>Emailer Recipients</Trans>
          </Column>
          <Column even={false} isHeader={true}>
            <Trans>Email</Trans>
          </Column>
        </RowDiv>
        <RowsContainer>
          {localNotificationSettings?.notificationUserTypes?.map((userType) => (
            <RowDiv>
              <Column align={"left"} textleft={"left"} isHeader={false} likeHeader={false} even={true}>
                <Trans>{userType.name}</Trans>
              </Column>
              <Column align={"right"} textleft={"right"} isHeaderToggle={true}>
                <ToggleWrapper>
                  <OnOffText>{true ? <Trans>On</Trans> : <Trans>Off</Trans>}</OnOffText>
                  <SwitchComponent
                    disabled={!isSystemSettingsEditable}
                    onChange={(e) => {
                      handleRecipientsChange(userType, e.target.checked);
                    }}
                    checked={isTheUserTypeEnabled(userType._id)}
                  />
                </ToggleWrapper>
              </Column>
            </RowDiv>
          ))}
        </RowsContainer>

        <>
          <CustomeUserDiv>
            <Trans>Custom Users</Trans>
          </CustomeUserDiv>
          <SettingsEmailSelector
            customEmails={customEmails}
            onChange={(field, email) => {
              handleCustomEmailsChange(email);
            }}
            isLoading={false}
            isDisabled={!isSystemSettingsEditable}
          />
        </>
      </>
    </SettingTableDescription>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationSetting: state.notificationSetting,
    isSystemSettingsEditable: state.user.isSystemSettingsEditable,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSystemSettingsUserTypes: () => dispatch(getSystemSettingsUserTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Recipients);
