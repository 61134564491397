/**
 * TODO:  Create a common filter component for all the reports/Dashboard pages when time permits
 * Do code optimization
 * Fix lint issues
 * Once completed, please remove this comment
 * Create common components
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import DateRangePicker from "../../../components/DatePicker.component";
// import FilterFropdown from "../../../components/DropdownFilter.component";
import { getZipDownloadUnauthorizedAccess } from "../../../redux/reports/action";
import { setDropdownAccountId, setDropdownWorksiteId } from "../../../redux/dropdownFilters/action";
import { setUnauthorizedAccessFilter } from "../../../redux/filters/action";
import { getUnauthorizedReports } from "../../../redux/reports/action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { toDownloadFiles } from "../../../redux/config/action";
import { apiCall } from "../../../utils/apiCall";
import Button from "../../../components/Button/Button.component";
import {
  RefreshButton,
  RefreshButtonDiv,
  PDFCSVREFRESHButton,
  PDFButtonWrapper,
  RefreshButtonD,
} from "./Reports.styles";
import DropdownComponent from "../../../components/DropdownComponent/Dropdown.component";
import DropdownWrapper from "../../../components/DropdownComponent/DropdownWrapper";
import UnAuthorizedReportsDataTable from "./TableWrapperUnauthorized/TableWrapper.component";
import MoreFilterMobileToggle from "../../../components/More Filter Button/MoreFilterButton.component";

function UnAuthorizedReports(props) {
  const {
    loginDetails,
    isSuperAdmin,
    isSiteAdmin,
    setDropdownAccountId,
    setDropdownWorksiteId,
    getUnauthorizedReports,
    unauthorizedReportList,
    totalUnauthorizedReportCount,
    getZipDownloadUnauthorizedAccess,
    getZipFileImpactReportLoading,
    dropdownWorksiteList,
    dropdownAccountList,
    dropdownAssetList,
    dropdownAssetTypeList,
    unauthorizedAccessFilter,
    setUnauthorizedAccessFilter,
    dropdownFilterAccountId,
    dropdownFilterWorksiteId,
    getUnauthorizedReportLoading,
  } = props;

  const { t } = useTranslation();
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [filterByTypeLabel, setFilterByTypeLabel] = useState("all");
  const [lastFilterData, setLastFilterData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(unauthorizedAccessFilter?.pageSize || 10);
  const [downloadReportSet, addDownloadReportSet] = useState(new Set());
  const [isAllSelected, setSelectAll] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountsList, setAccountsList] = useState([]);

  const FilterByTypeLabelData = {
    asset: "Asset",
    assetType: "Asset Type",
    inspectedBy: "Inspected By",
    inspectionResult: "Impact Result",
  };

  let { filterByType, endDate, startDate, worksiteId } = unauthorizedAccessFilter;

  let filterByTypeData = [
    { name: "Asset", _id: "asset" },
    { name: "Asset Type", _id: "assetType" },
  ];

  const handleReportZipDownload = () => {
    if (downloadReportSet.size <= 0) {
      return;
    }
    let reportArray = Array.from(downloadReportSet);
    getZipDownloadUnauthorizedAccess(reportArray);
  };

  const handleCheckboxClick = (e) => {
    const newDownloadReportSet = new Set(downloadReportSet);
    if (e.target.checked) {
      newDownloadReportSet.add(e.target.value);
    } else {
      newDownloadReportSet.delete(e.target.value);
    }
    addDownloadReportSet(newDownloadReportSet);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newDownloadReportSet = new Set(downloadReportSet);
      unauthorizedReportList &&
        unauthorizedReportList.forEach((data, i) => {
          newDownloadReportSet.add(data._id);
        });
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(true);
    } else {
      const newDownloadReportSet = new Set();
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(false);
    }
  };

  const changeFilterByType = (value) => {
    setUnauthorizedAccessFilter({
      ...unauthorizedAccessFilter,
      filterByType: value,
      pageNo: 1,
    });
    setFilterByTypeLabel(FilterByTypeLabelData[value]);
    if (value === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (value === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    } else if (value === "inspectedBy") {
      // New backend api

      const url = `/dropdown/users?accid=${
        isSuperAdmin ? unauthorizedAccessFilter.accountId : loginDetails.accountId
      }&siteid=${
        isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownFilterWorksiteId
      }&status=true`;

      apiCall(url).then((resp) => {
        // let newData = [];
        // let nameSet = new Set();
        // resp.data.data.map((data) => {
        //   if (nameSet.has(data.userId && data.userId.fullName)) {
        //     return;
        //   }
        //   nameSet.add(data.userId && data.userId.fullName);
        //   newData.push({
        //     _id: data.userId && data.userId._id,
        //     fullName: data.userId && data.userId.fullName,
        //   });
        // });
        setLastFilterData(resp.data?.data?.data);
      });
    } else if (value === "inspectionResult") {
      setLastFilterData([
        { fullName: "High", _id: "high" },
        { fullName: "Medium", _id: "medium" },
        { fullName: "Low", _id: "low" },
      ]);
    }

    setPageNo(1);
  };

  useEffect(() => {
    setUnauthorizedAccessFilter({
      ...unauthorizedAccessFilter,
      assetId: "all",
      assetTypeId: "all",
      inspectorId: "all",
      impactSeverity: "all",
      pageNo: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownFilterAccountId]);

  useEffect(() => {
    if (filterByType === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (filterByType === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    }
  }, [dropdownAssetTypeList, dropdownAssetList]);

  const changeLastFilter = (value) => {
    if (unauthorizedAccessFilter.filterByType === "asset") {
      setUnauthorizedAccessFilter({
        ...unauthorizedAccessFilter,
        assetId: value,
        pageNo: 1,
      });
    } else if (unauthorizedAccessFilter.filterByType === "assetType") {
      setUnauthorizedAccessFilter({
        ...unauthorizedAccessFilter,
        assetTypeId: value,
        pageNo: 1,
      });
    } else if (unauthorizedAccessFilter.filterByType === "inspectedBy") {
      setUnauthorizedAccessFilter({
        ...unauthorizedAccessFilter,
        inspectorId: value,
        pageNo: 1,
      });
    } else if (unauthorizedAccessFilter.filterByType === "inspectionResult") {
      setUnauthorizedAccessFilter({
        ...unauthorizedAccessFilter,
        impactSeverity: value,
        pageNo: 1,
      });
    }
    setPageNo(1);
  };

  const handlePageLength = (e) => {
    setPageSize(e.target.value);
    setUnauthorizedAccessFilter({
      ...unauthorizedAccessFilter,
      pageSize: e.target.value,
      pageNo: 1,
    });
    addDownloadReportSet(new Set());
    setPageNo(1);
  };

  useEffect(() => {
    setUnauthorizedAccessFilter({
      ...unauthorizedAccessFilter,
      worksiteId: "all",
    });
    if (unauthorizedAccessFilter.accountId === "all") {
      setSelectedAccount(null);
      setShowMoreFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unauthorizedAccessFilter.accountId]);

  useEffect(() => {
    setUnauthorizedAccessFilter({
      ...unauthorizedAccessFilter,
      assetTypeId: "all",
      assetId: "all",
      inspectorId: "all",
      impactSeverity: "all",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unauthorizedAccessFilter.filterByType]);

  const nextPage = () => {
    if (pageNo > Math.ceil((totalUnauthorizedReportCount || 0) / pageSize) - 1) {
      return;
    }
    let newPage = pageNo + 1;
    setUnauthorizedAccessFilter({
      ...unauthorizedAccessFilter,
      pageNo: newPage,
    });
    setPageNo(newPage);
    addDownloadReportSet(new Set());
  };

  const previousPage = () => {
    if (pageNo <= 1) {
      return;
    }
    let newPage = pageNo - 1;
    setUnauthorizedAccessFilter({
      ...unauthorizedAccessFilter,
      pageNo: newPage,
    });
    setPageNo(newPage);
    addDownloadReportSet(new Set());
  };

  useEffect(() => {
    getUnauthorizedReports(unauthorizedAccessFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unauthorizedAccessFilter]);

  useEffect(() => {
    if (!isSuperAdmin) {
      setUnauthorizedAccessFilter({
        ...unauthorizedAccessFilter,
        accountId: loginDetails && loginDetails.accountId,
      });
    }
    getUnauthorizedReports(unauthorizedAccessFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails]);

  useEffect(() => {
    const startResultNumber = pageSize * (pageNo - 1);
    const endResultNumber =
      parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalUnauthorizedReportCount
        ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
        : totalUnauthorizedReportCount;
    const currentTotalReports = endResultNumber - startResultNumber;

    if (downloadReportSet.size === currentTotalReports && currentTotalReports !== 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportSet]);

  const refreshData = () => {
    getUnauthorizedReports(unauthorizedAccessFilter);
  };

  useEffect(() => {
    const accounts = [];
    dropdownAccountList.forEach((acc) => {
      accounts.push({
        value: acc._id,
        label: acc.companyName,
      });
      if (acc._id === unauthorizedAccessFilter.accountId) {
        setSelectedAccount({
          value: acc._id,
          label: acc.companyName,
        });
      }
    });
    setAccountsList(accounts);
  }, [dropdownAccountList]);

  const [rotation, setRotation] = useState(0);
  const [isRotating, setIsRotating] = useState(false);

  const onRefresh = () => {
    setIsRotating(true);

    setTimeout(() => {
      setIsRotating(false);
      setRotation(rotation + 360);
    }, 2000);
  };

  return (
    <>
      <MoreFilterMobileToggle>
        <>
          <Row>
            {isSuperAdmin && (
              <Col lg={2}>
                <DropdownComponent
                  label={t("Account")}
                  placeholder={t("Select an Account")}
                  options={accountsList}
                  value={selectedAccount}
                  handleFilter={(selectedData) => {
                    setSelectedAccount(selectedData);
                    setUnauthorizedAccessFilter({
                      ...unauthorizedAccessFilter,
                      accountId: selectedData.value,
                    });
                    setDropdownAccountId(selectedData.value);
                  }}
                  size="medium"
                />
              </Col>
            )}
            {((isSuperAdmin && selectedAccount) || !isSuperAdmin) && (
              <>
                {!isSiteAdmin && (
                  <Col lg={2}>
                    <DropdownWrapper
                      filter={{
                        type: t("Worksite"),
                        name: "worksiteName",
                        key: "name",
                        data: dropdownWorksiteList,
                      }}
                      value={worksiteId}
                      //  label={t("filter")}
                      handleFilter={(value) => {
                        setUnauthorizedAccessFilter({
                          ...unauthorizedAccessFilter,
                          worksiteId: value,
                          assetId: null,
                          assetTypeId: null,
                        });
                        setDropdownWorksiteId(value);
                      }}
                    />
                  </Col>
                )}
                <Col lg={4}>
                  {/* <label style={labelStyle} className="text-open-sans">
                      <Trans>Date Range</Trans>
                    </label> */}
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    setDate={setUnauthorizedAccessFilter}
                    filterState={unauthorizedAccessFilter}
                  />
                </Col>
                <Col className="text-left" style={{ marginTop: "20px" }}>
                  <Button
                    iconClass="filter"
                    label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                    onClick={() => setShowMoreFilter(!showMoreFilter)}
                    showIcon
                    buttonType={showMoreFilter ? "primary" : "secondary"}
                  />
                </Col>
              </>
            )}
          </Row>
          {showMoreFilter && (
            <Row>
              <Col lg={2}>
                <DropdownWrapper
                  filter={{
                    type: t("Filter By"),
                    name: "filterBy",
                    placeholder: t("Select a Filter"),
                    key: "name",
                    data: filterByTypeData,
                  }}
                  value={unauthorizedAccessFilter.filterByType}
                  //  label={t("filter")}
                  handleFilter={(e) => changeFilterByType(e)}
                  useAsDropdown={true}
                />
              </Col>
              {filterByTypeLabel !== "all" ? (
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: `${filterByTypeLabel}`,
                      key:
                        filterByType === "asset"
                          ? "assetName"
                          : filterByType === "assetType"
                            ? "AssetTypeName"
                            : "fullName",
                      data: lastFilterData,
                    }}
                    value={
                      unauthorizedAccessFilter[
                        filterByType === "asset"
                          ? "assetId"
                          : filterByType === "assetType"
                            ? "assetTypeId"
                            : filterByType === "inspectionResult"
                              ? "impactSeverity"
                              : "inspectorId"
                      ]
                    }
                    label={t("filter")}
                    handleFilter={(e) => changeLastFilter(e)}
                  />
                </Col>
              ) : (
                ""
              )}
            </Row>
          )}
        </>
      </MoreFilterMobileToggle>

      <PDFCSVREFRESHButton>
        <PDFButtonWrapper disabled={downloadReportSet.size <= 0}>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                <Trans>PDF_DOWNLOAD</Trans>
              </Tooltip>
            }
          >
            <Button
              onClick={handleReportZipDownload}
              iconOnly
              label={
                getZipFileImpactReportLoading && (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                )
              }
              disabled={downloadReportSet.size == 0}
              showIcon
              iconClass="pdf-icon"
              buttonType="iconPDF"
            />
          </OverlayTrigger>
        </PDFButtonWrapper>
        <RefreshButtonDiv>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                <Trans>Refresh</Trans>
              </Tooltip>
            }
          >
            <RefreshButton onClick={refreshData}>
              <RefreshButtonD
                rotating={isRotating}
                onClick={onRefresh}
                style={{ transform: `rotate(${rotation}deg)` }}
                className="icon icon-update"
              ></RefreshButtonD>
            </RefreshButton>
          </OverlayTrigger>
        </RefreshButtonDiv>
      </PDFCSVREFRESHButton>
      <br />

      <UnAuthorizedReportsDataTable
        data={unauthorizedReportList}
        isSiteAdmin={isSiteAdmin}
        isSuperAdmin={isSuperAdmin}
        handleSelectAll={handleSelectAll}
        handleCheckboxClick={handleCheckboxClick}
        downloadReportSet={downloadReportSet}
        isAllSelected={isAllSelected}
        handlePageLength={handlePageLength}
        pageSize={pageSize}
        pageNo={pageNo}
        totalPageReport={totalUnauthorizedReportCount}
        previousPage={previousPage}
        nextPage={nextPage}
        selectedAccount={selectedAccount}
        rowHeight={"370"}
        loading={getUnauthorizedReportLoading}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  unauthorizedReportList: state.reports.unauthorizedReportList,
  getUnauthorizedReportLoading: state.reports.getUnauthorizedReportLoading,
  totalUnauthorizedReportCount: state.reports.totalUnauthorizedReportCount,
  getZipFileImpactReportLoading: state.reports.getZipFileImpactReportLoading,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAssetList: state.dropdownFilters.assetList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  unauthorizedAccessFilter: state.filters.unauthorizedAccessFilter,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  fileDownloadLoading: state.config.fileDownloadLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getUnauthorizedReports: (filterData) => dispatch(getUnauthorizedReports(filterData)),
  getZipDownloadUnauthorizedAccess: (filterReportArray) =>
    dispatch(getZipDownloadUnauthorizedAccess(filterReportArray)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (worksiteId) => dispatch(setDropdownWorksiteId(worksiteId)),
  setUnauthorizedAccessFilter: (filterData) => dispatch(setUnauthorizedAccessFilter(filterData)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnAuthorizedReports));
