import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Trans } from "react-i18next";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Form } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import { getTimeWithTimeZone } from "../../../utils/timezoneConvert";
import { apiCall } from "../../../utils/apiCall";
import awsConstant from "../../../constant/awsFolder.json";
import { toDownloadFiles } from "../../../redux/config/action";
import {
  AssetChecklistTableWrapper,
  RowDiv,
  Column,
  RowsContainer,
  NoSectionContainer,
  SpanDiv,
  ViewIconButton,
  DownloadStyledButton,
  MobileResponsive,
  AssetOverviewPage,
  ButtonWrapperDiv,
  ButtonWrapper,
} from "./AssetOverviewInspectionReports.component.styles";

function AssetOverviewInspectionReports({
  loginDetails,
  assetId,
  assetOverview,
  AssetLoading,
  toDownloadFiles,
}) {
  const [inspectionReport, setInspectionReport] = useState(null);
  const [IsLoading, setIsLoading] = useState(false);

  const loadInspectionReport = async () => {
    setInspectionReport([]);
    setIsLoading(true);
    try {
      const response = await apiCall(`/asset/inspectionreports/all/all/all/${assetId}/all/all/all/1/5/all`);
      if (response.data.success === true) {
        setIsLoading(false);
        setInspectionReport(response.data.data);
      } else {
        setIsLoading(false);
        setInspectionReport([]);
      }
    } catch (error) {
      console.log("Error occurred");
    }
  };

  useEffect(() => {
    setInspectionReport([]);
    if (assetOverview) {
      loadInspectionReport();
    }
  }, [assetOverview]);

  const viewReportClickHandler = (e, data) => {
    localStorage.setItem("inspectionReportObject", JSON.stringify(data));
    window.open("/#/app/inspection-report", "_blank");
  };

  const downloadReportApiCall = (id) => {
    toDownloadFiles(awsConstant.inspectionReport, `${id}.pdf`);
  };

  return (
    <AssetOverviewPage>
      <div style={{ width: "100%" }}>
        {(IsLoading || AssetLoading) && (
          <div>
            <Skeleton className="m-2" count={4} width={"90%"} />
          </div>
        )}
        <>
          {assetOverview &&
            !AssetLoading &&
            !IsLoading &&
            inspectionReport &&
            inspectionReport?.length > 0 && (
              <>
                <AssetChecklistTableWrapper>
                  <RowDiv isHeader={true} className="forResponsive">
                    <Column isHeader={true} align={"left"}>
                      <Trans>Inspected By</Trans>
                    </Column>
                    <Column isHeader={true} align={"left"}>
                      <Trans>Date, Time</Trans>
                    </Column>
                    <Column isHeader={true} align={"center"}>
                      <Trans>Result</Trans>
                    </Column>
                    <Column isHeader={true} align={"center"}>
                      <Trans>Action</Trans>
                    </Column>
                  </RowDiv>
                  <RowsContainer>
                    {inspectionReport &&
                      inspectionReport.map((data, i) => (
                        <RowDiv key={i}>
                          <Column
                            align={"left"}
                            textleft={"left"}
                            widthResp={"75%"}
                            floatResp={"left"}
                            RmarginResp={"5%"}
                          >
                            <MobileResponsive>
                              <Trans>Inspected By</Trans>
                            </MobileResponsive>
                            <span title={data.inspectorId && data.inspectorId.fullName}>
                              {data.inspectorId && data.inspectorId.fullName}
                            </span>
                          </Column>

                          <Column
                            align={"left"}
                            textleft={"left"}
                            widthResp={"75%"}
                            floatResp={"left"}
                            RmarginResp={"5%"}
                          >
                            <MobileResponsive>
                              <Trans>Date, Time</Trans>
                            </MobileResponsive>
                            <span>
                              {assetOverview &&
                                assetOverview.siteId &&
                                assetOverview.siteId.timeZone &&
                                moment(data?.inspectionAt)
                                  .utcOffset(data?.siteId?.timeZone?.split?.(" ")?.[0])
                                  .format("MMM-DD-YYYY hh:mm A")}{" "}
                              {getTimeWithTimeZone(data?.siteId?.timeZone)}
                            </span>
                          </Column>
                          <Column align={"center"} textleft={"center"} widthResp={"20%"} floatResp={"none"}>
                            <MobileResponsive>
                              <Trans>Result</Trans>
                            </MobileResponsive>
                            <ButtonWrapperDiv>
                              {data.status == "passed" && (
                                <ButtonWrapper
                                  className="btn PassFail btn-outline-Pass"
                                  style={{ cursor: "default" }}
                                >
                                  Pass
                                </ButtonWrapper>
                              )}
                              {data.status == "failed" && (
                                <ButtonWrapper
                                  className="btn PassFail btn-outline-Fail"
                                  style={{ cursor: "default" }}
                                >
                                  Fail
                                </ButtonWrapper>
                              )}
                              {data.status == "timedout" && (
                                <ButtonWrapper
                                  className="btn PassFail btn-outline-Timedout"
                                  style={{ cursor: "default" }}
                                >
                                  Timed Out
                                </ButtonWrapper>
                              )}

                              {/* {data.status ? <ResultPass /> : <ResultFail />} */}
                            </ButtonWrapperDiv>
                          </Column>

                          <Column align={"center"} textleft={"center"} widthResp={"20%"} floatResp={"none"}>
                            <MobileResponsive>
                              <Trans>Action</Trans>
                            </MobileResponsive>{" "}
                            <span className="vierDeleteButton">
                              <ViewIconButton className="icon icon-view" />

                              <DownloadStyledButton
                                className="icon icon-download"
                                onClick={() => downloadReportApiCall(data._id)}
                              />
                            </span>
                          </Column>
                        </RowDiv>
                      ))}
                  </RowsContainer>
                </AssetChecklistTableWrapper>
              </>
            )}
          {assetOverview &&
            !AssetLoading &&
            !IsLoading &&
            inspectionReport &&
            inspectionReport.length <= 0 && (
              <NoSectionContainer>
                <SpanDiv>
                  <Trans>There is no report on this asset right now</Trans>.
                </SpanDiv>
                <SpanDiv>
                  <Trans>Click 'INSPECT' to inspect an asset</Trans>.
                </SpanDiv>
              </NoSectionContainer>
            )}
        </>
      </div>
    </AssetOverviewPage>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetOverview: state.assets.AssetOverview,
  AssetLoading: state.assets.AssetLoading,
});

const mapDispatchToProps = (dispatch) => ({
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetOverviewInspectionReports));
