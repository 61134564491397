import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ViewAssetTypeOverviewTab from "./ViewAssetTypeOverviewTab.component";

import Skeleton from "react-loading-skeleton";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import {
  AssetTypeTopHeader,
  DetailsWrapper,
  AssetTypeHeadDetail,
  AssetTypeDataDescp,
  AssetTypeTabDiv,
  NoSectionStyle,
  AssetTypePageStyled,
} from "./ViewAssetTypeOverviewTab.component.styles";

const ViewAssetTypeOverview = (props) => {
  const { assetTypeOverview, AssetTypeLoading, accountListById, currentTimeZone } = props;
  const { createdAt, AssetTypeCreatedOn } = assetTypeOverview || {};
  const dateOfCreation = createdAt || AssetTypeCreatedOn;
  const timeZone = currentTimeZone || dateOfCreation?.split?.(" ")[0];

  const addedOn =
    assetTypeOverview &&
    dateOfCreation &&
    moment(dateOfCreation).utcOffset(timeZone)?.format(" MMMM DD YYYY");

  return (
    <AssetTypePageStyled>
      <>
        <>
          {AssetTypeLoading && (
            <span>
              <Row>
                <Col md={12}>
                  <Skeleton height={20} />
                  <Skeleton height={10} count={2} />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="mt-4">
                  <Skeleton height={20} width={100} />
                  <Skeleton height={10} count={4} />
                </Col>
              </Row>
            </span>
          )}
          {assetTypeOverview && !AssetTypeLoading && (
            <>
              <AssetTypeTopHeader>
                <DetailsWrapper>
                  <AssetTypeHeadDetail title={assetTypeOverview ? assetTypeOverview.AssetTypeName : ""}>
                    {assetTypeOverview ? assetTypeOverview.AssetTypeName : ""}
                  </AssetTypeHeadDetail>

                  <AssetTypeDataDescp>
                    <span title={assetTypeOverview ? assetTypeOverview.AssetTypeDescription : ""}>
                      {assetTypeOverview ? assetTypeOverview.AssetTypeDescription : ""}
                    </span>
                  </AssetTypeDataDescp>

                  <AssetTypeDataDescp>
                    <Trans>Added On</Trans>:
                    <span title={addedOn}>
                      <Trans>{addedOn}</Trans>
                    </span>
                  </AssetTypeDataDescp>

                  <AssetTypeDataDescp>
                    <Trans>Created By</Trans>:&nbsp;
                    <span title="SIERA.AI">
                      <Trans>SIERA.AI</Trans>
                    </span>
                  </AssetTypeDataDescp>

                  <AssetTypeDataDescp>
                    <Trans>AccountLabel</Trans>
                    <span title={accountListById?.[assetTypeOverview?.accountId]?.companyName}>
                      <Trans> {accountListById?.[assetTypeOverview?.accountId]?.companyName}</Trans>
                    </span>
                  </AssetTypeDataDescp>
                </DetailsWrapper>
              </AssetTypeTopHeader>
              <AssetTypeTabDiv>
                <ViewAssetTypeOverviewTab
                  typeAttributes={assetTypeOverview?.AssetTypeAttributes}
                  assignedChecklists={assetTypeOverview?.checklists}
                />
              </AssetTypeTabDiv>
            </>
          )}

          {!assetTypeOverview && !AssetTypeLoading && (
            <NoSectionStyle>
              <p>
                <Trans>NO_ASSET_TYPE_IN_ACCOUNT</Trans>
              </p>
              <div className="clickDesktop">
                <Trans>CLICK_ADD_ASSET_TYPE_BUTTON</Trans>
              </div>
              <div className="tapMobile">
                <Trans>CLICK_ADD_ASSET_TYPE_BUTTON_MOB</Trans>
              </div>
            </NoSectionStyle>
          )}
        </>
      </>
    </AssetTypePageStyled>
  );
};

const mapStateToProps = (state) => ({
  assetTypeOverview: state.assets.AssetTypeOverview,
  AssetTypeLoading: state.assets.assetTypeDetailLoading,
  accountListById: state.dropdownFilters.accountListById,
  isSuperAdmin: state.user.isSuperAdmin,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAssetTypeOverview));
