let timeZone = [
  {
    value: "Dateline Standard Time",
    short: "DST",
    offset: -12,
    isDST: false,
    text: "(UTC-12:00) International Date Line West",
    utc: ["Etc/GMT+12"],
    _id: "-12:00 International Date Line West",
    label: "Dateline Standard Time",
  },
  {
    value: "UTC-11",
    short: "U",
    offset: -11,
    isDST: false,
    text: "(UTC-11:00) Coordinated Universal Time-11",
    utc: ["Etc/GMT+11", "Pacific/Midway", "Pacific/Niue", "Pacific/Pago_Pago"],
    _id: "-11:00 Coordinated Universal Time-11",
    label: "UTC-11",
  },
  {
    value: "Hawaiian Standard Time",
    short: "HST",
    offset: -10,
    isDST: false,
    text: "(UTC-10:00) Hawaii",
    utc: ["Etc/GMT+10", "Pacific/Honolulu", "Pacific/Johnston", "Pacific/Rarotonga", "Pacific/Tahiti"],
    _id: "-10:00 Hawaii",
    label: "Hawaiian Standard Time",
  },
  {
    value: "Alaskan Standard Time",
    short: "AKDT",
    offset: -8,
    isDST: true,
    text: "(UTC-09:00) Alaska",
    utc: ["America/Anchorage", "America/Juneau", "America/Nome", "America/Sitka", "America/Yakutat"],
    _id: "-08:00 Alaska",
    label: "Alaska Daylight Time",
  },
  {
    value: "Alaskan Standard Time",
    short: "AKST",
    offset: -8,
    isDST: false,
    text: "(UTC-09:00) Alaska",
    utc: ["America/Anchorage", "America/Juneau", "America/Nome", "America/Sitka", "America/Yakutat"],
    _id: "-09:00 Alaska",
    label: "Alaskan Standard Time",
  },
  {
    value: "Pacific Standard Time (Mexico)",
    short: "PDT",
    offset: -7,
    isDST: true,
    text: "(UTC-08:00) Baja California",
    utc: ["America/Santa_Isabel"],
    _id: "-08:00 Baja California",
    label: "Pacific Standard Time (Mexico)",
  },
  {
    value: "Pacific Daylight Time",
    short: "PDT",
    offset: -7,
    isDST: true,
    text: "(UTC-07:00) Pacific Daylight Time (US & Canada)",
    utc: ["America/Los_Angeles", "America/Tijuana", "America/Vancouver"],
    _id: "-07:00 Pacific Daylight Time (US & Canada)",
    label: "Pacific Daylight Time",
  },
  {
    value: "Pacific Standard Time",
    short: "PST",
    offset: -8,
    isDST: false,
    text: "(UTC-08:00) Pacific Standard Time (US & Canada)",
    utc: ["America/Los_Angeles", "America/Tijuana", "America/Vancouver", "PST8PDT"],
    _id: "-08:00 Pacific Standard Time (US & Canada)",
    label: "Pacific Standard Time",
  },
  {
    value: "US Mountain Standard Time",
    short: "UMST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) Arizona",
    utc: [
      "America/Creston",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "America/Whitehorse",
      "Etc/GMT+7",
    ],
    _id: "-07:00 Arizona",
    label: "US Mountain Standard Time",
  },
  {
    value: "Mountain Standard Time (Mexico)",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    utc: ["America/Chihuahua", "America/Mazatlan"],
    _id: "-07:00 Chihuahua, La Paz, Mazatlan",
    label: "Mountain Standard Time (Mexico)",
  },
  {
    value: "Mountain Standard Time",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-07:00) Mountain Time (US & Canada)",
    utc: [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT",
    ],
    _id: "-07:00 Mountain Time (US & Canada)",
    label: "Mountain Standard Time",
  },
  {
    value: "Central America Standard Time",
    short: "CAST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) Central America",
    utc: [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos",
    ],
    _id: "-06:00 Central America",
    label: "Central America Standard Time",
  },
  {
    value: "Central Standard Time",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-06:00) Central Time (US & Canada)",
    utc: [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT",
    ],
    _id: "-06:00 Central Time (US & Canada)",
    label: "Central Standard Time",
  },
  {
    value: "Central Standard Time (Mexico)",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    utc: [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey",
    ],
    _id: "-06:00 Guadalajara, Mexico City, Monterrey",
    label: "Central Standard Time (Mexico)",
  },
  {
    value: "Canada Central Standard Time",
    short: "CCST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) Saskatchewan",
    utc: ["America/Regina", "America/Swift_Current"],
    _id: "-06:00 Saskatchewan",
    label: "Canada Central Standard Time",
  },
  {
    value: "SA Pacific Standard Time",
    short: "SPST",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) Bogota, Lima, Quito",
    utc: [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5",
    ],
    _id: "-05:00 Bogota, Lima, Quito",
    label: "SA Pacific Standard Time",
  },
  {
    value: "Eastern Standard Time",
    short: "EST",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) Eastern Time (US & Canada)",
    utc: [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
    ],
    _id: "-05:00 Eastern Time (US & Canada)",
    label: "Eastern Standard Time",
  },
  {
    value: "Eastern Daylight Time",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) Eastern Daylight Time (US & Canada)",
    utc: [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
    ],
    _id: "-04:00 Eastern Daylight Time (US & Canada)",
    label: "Eastern Daylight Time",
  },
  {
    value: "US Eastern Standard Time",
    short: "UEDT",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) Indiana (East)",
    utc: ["America/Indiana/Marengo", "America/Indiana/Vevay", "America/Indianapolis"],
    _id: "-05:00 Indiana (East)",
    label: "US Eastern Standard Time",
  },
  {
    value: "Venezuela Standard Time",
    short: "VST",
    offset: -4.5,
    isDST: false,
    text: "(UTC-04:30) Caracas",
    utc: ["America/Caracas"],
    _id: "-04:30 Caracas",
    label: "Venezuela Standard Time",
  },
  {
    value: "Paraguay Standard Time",
    short: "PYT",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) Asuncion",
    utc: ["America/Asuncion"],
    _id: "-04:00 Asuncion",
    label: "Paraguay Standard Time",
  },
  {
    value: "Atlantic Standard Time",
    short: "ADT",
    offset: -3,
    isDST: true,
    text: "(UTC-04:00) Atlantic Time (Canada)",
    utc: [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda",
    ],
    _id: "-04:00 Atlantic Time (Canada)",
    label: "Atlantic Standard Time",
  },
  {
    value: "Central Brazilian Standard Time",
    short: "CBST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) Cuiaba",
    utc: ["America/Campo_Grande", "America/Cuiaba"],
    _id: "-04:00 Cuiaba",
    label: "Central Brazilian Standard Time",
  },
  {
    value: "SA Western Standard Time",
    short: "SWST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    utc: [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4",
    ],
    _id: "-04:00 Georgetown, La Paz, Manaus, San Juan",
    label: "SA Western Standard Time",
  },
  {
    value: "Pacific SA Standard Time",
    short: "PSST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) Santiago",
    utc: ["America/Santiago", "Antarctica/Palmer"],
    _id: "-04:00 Santiago",
    label: "Pacific SA Standard Time",
  },
  {
    value: "Newfoundland Standard Time",
    short: "NDT",
    offset: -2.5,
    isDST: true,
    text: "(UTC-03:30) Newfoundland",
    utc: ["America/St_Johns"],
    _id: "-03:30 Newfoundland",
    label: "Newfoundland Standard Time",
  },
  {
    value: "E. South America Standard Time",
    short: "ESAST",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) Brasilia",
    utc: ["America/Sao_Paulo"],
    _id: "-03:00 Brasilia",
    label: "E. South America Standard Time",
  },
  {
    value: "Argentina Standard Time",
    short: "AST",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) Buenos Aires",
    utc: [
      "America/Argentina/Buenos_Aires",
      "America/Argentina/Catamarca",
      "America/Argentina/Cordoba",
      "America/Argentina/Jujuy",
      "America/Argentina/La_Rioja",
      "America/Argentina/Mendoza",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza",
    ],
    _id: "-03:00 Buenos Aires",
    label: "Argentina Standard Time",
  },
  {
    value: "SA Eastern Standard Time",
    short: "SEST",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) Cayenne, Fortaleza",
    utc: [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3",
    ],
    _id: "-03:00 Cayenne, Fortaleza",
    label: "SA Eastern Standard Time",
  },
  {
    value: "Greenland Standard Time",
    short: "GDT",
    offset: -3,
    isDST: true,
    text: "(UTC-03:00) Greenland",
    utc: ["America/Godthab"],
    _id: "-03:00 Greenland",
    label: "Greenland Standard Time",
  },
  {
    value: "Montevideo Standard Time",
    short: "MST",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) Montevideo",
    utc: ["America/Montevideo"],
    _id: "-03:00 Montevideo",
    label: "Montevideo Standard Time",
  },
  {
    value: "Bahia Standard Time",
    short: "BST",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) Salvador",
    utc: ["America/Bahia"],
    _id: "-03:00 Salvador",
    label: "Bahia Standard Time",
  },
  {
    value: "UTC-02",
    short: "U",
    offset: -2,
    isDST: false,
    text: "(UTC-02:00) Coordinated Universal Time-02",
    utc: ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"],
    _id: "-02:00 Coordinated Universal Time-02",
    label: "UTC-02",
  },
  {
    value: "Mid-Atlantic Standard Time",
    short: "MDT",
    offset: -1,
    isDST: true,
    text: "(UTC-02:00) Mid-Atlantic - Old",
    utc: [],
    _id: "-02:00 Mid-Atlantic - Old",
    label: "Mid-Atlantic Standard Time",
  },
  {
    value: "Azores Standard Time",
    short: "ADT",
    offset: 0,
    isDST: true,
    text: "(UTC-01:00) Azores",
    utc: ["America/Scoresbysund", "Atlantic/Azores"],
    _id: "-01:00 Azores",
    label: "Azores Standard Time",
  },
  {
    value: "Cape Verde Standard Time",
    short: "CVST",
    offset: -1,
    isDST: false,
    text: "(UTC-01:00) Cape Verde Is.",
    utc: ["Atlantic/Cape_Verde", "Etc/GMT+1"],
    _id: "-01:00 Cape Verde Is.",
    label: "Cape Verde Standard Time",
  },
  {
    value: "Morocco Standard Time",
    short: "MDT",
    offset: 1,
    isDST: true,
    text: "(UTC) Casablanca",
    utc: ["Africa/Casablanca", "Africa/El_Aaiun"],
    _id: " Casablanca",
    label: "Morocco Standard Time",
  },
  {
    value: "UTC",
    short: "UTC",
    offset: 0,
    isDST: false,
    text: "(UTC) Coordinated Universal Time",
    utc: ["America/Danmarkshavn", "Etc/GMT"],
    _id: " Coordinated Universal Time",
    label: "UTC",
  },
  {
    value: "GMT Standard Time",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC) Edinburgh, London",
    utc: ["Europe/Isle_of_Man", "Europe/Guernsey", "Europe/Jersey", "Europe/London"],
    _id: " Edinburgh, London",
    label: "GMT Standard Time",
  },
  {
    value: "British Summer Time",
    short: "BST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Edinburgh, London",
    utc: ["Europe/Isle_of_Man", "Europe/Guernsey", "Europe/Jersey", "Europe/London"],
    _id: "+01:00 Edinburgh, London",
    label: "British Summer Time",
  },
  {
    value: "GMT Standard Time",
    short: "GDT",
    offset: 1,
    isDST: true,
    text: "(UTC) Dublin, Lisbon",
    utc: ["Atlantic/Canary", "Atlantic/Faeroe", "Atlantic/Madeira", "Europe/Dublin", "Europe/Lisbon"],
    _id: " Dublin, Lisbon",
    label: "GMT Standard Time",
  },
  {
    value: "Greenwich Standard Time",
    short: "GST",
    offset: 0,
    isDST: false,
    text: "(UTC) Monrovia, Reykjavik",
    utc: [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena",
    ],
    _id: " Monrovia, Reykjavik",
    label: "Greenwich Standard Time",
  },
  {
    value: "W. Europe Standard Time",
    short: "WEDT",
    offset: 2,
    isDST: true,
    text: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    utc: [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Stockholm",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich",
    ],
    _id: "+01:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    label: "W. Europe Standard Time",
  },
  {
    value: "Central Europe Standard Time",
    short: "CEDT",
    offset: 2,
    isDST: true,
    text: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    utc: [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane",
    ],
    _id: "+01:00 Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    label: "Central Europe Standard Time",
  },
  {
    value: "Romance Standard Time",
    short: "RDT",
    offset: 2,
    isDST: true,
    text: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    utc: ["Africa/Ceuta", "Europe/Brussels", "Europe/Copenhagen", "Europe/Madrid", "Europe/Paris"],
    _id: "+01:00 Brussels, Copenhagen, Madrid, Paris",
    label: "Romance Standard Time",
  },
  {
    value: "Central European Standard Time",
    short: "CEDT",
    offset: 2,
    isDST: true,
    text: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    utc: ["Europe/Sarajevo", "Europe/Skopje", "Europe/Warsaw", "Europe/Zagreb"],
    _id: "+01:00 Sarajevo, Skopje, Warsaw, Zagreb",
    label: "Central European Standard Time",
  },
  {
    value: "W. Central Africa Standard Time",
    short: "WCAST",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) West Central Africa",
    utc: [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1",
    ],
    _id: "+01:00 West Central Africa",
    label: "W. Central Africa Standard Time",
  },
  {
    value: "Namibia Standard Time",
    short: "NST",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Windhoek",
    utc: ["Africa/Windhoek"],
    _id: "+01:00 Windhoek",
    label: "Namibia Standard Time",
  },
  {
    value: "GTB Standard Time",
    short: "GDT",
    offset: 3,
    isDST: true,
    text: "(UTC+02:00) Athens, Bucharest",
    utc: ["Asia/Nicosia", "Europe/Athens", "Europe/Bucharest", "Europe/Chisinau"],
    _id: "+02:00 Athens, Bucharest",
    label: "GTB Standard Time",
  },
  {
    value: "Middle East Standard Time",
    short: "MEDT",
    offset: 3,
    isDST: true,
    text: "(UTC+02:00) Beirut",
    utc: ["Asia/Beirut"],
    _id: "+02:00 Beirut",
    label: "Middle East Standard Time",
  },
  {
    value: "Egypt Standard Time",
    short: "EST",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Cairo",
    utc: ["Africa/Cairo"],
    _id: "+02:00 Cairo",
    label: "Egypt Standard Time",
  },
  {
    value: "Syria Standard Time",
    short: "SDT",
    offset: 3,
    isDST: true,
    text: "(UTC+02:00) Damascus",
    utc: ["Asia/Damascus"],
    _id: "+02:00 Damascus",
    label: "Syria Standard Time",
  },
  {
    value: "E. Europe Standard Time",
    short: "EEDT",
    offset: 3,
    isDST: true,
    text: "(UTC+02:00) E. Europe",
    utc: [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Kyiv",
      "Europe/Mariehamn",
      "Europe/Nicosia",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhhorod",
      "Europe/Vilnius",
      "Europe/Zaporizhzhia",
    ],
    _id: "+02:00 E. Europe",
    label: "E. Europe Standard Time",
  },
  {
    value: "South Africa Standard Time",
    short: "SAST",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Harare, Pretoria",
    utc: [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2",
    ],
    _id: "+02:00 Harare, Pretoria",
    label: "South Africa Standard Time",
  },
  {
    value: "FLE Standard Time",
    short: "FDT",
    offset: 3,
    isDST: true,
    text: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    utc: [
      "Europe/Helsinki",
      "Europe/Kyiv",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhhorod",
      "Europe/Vilnius",
      "Europe/Zaporizhzhia",
    ],
    _id: "+02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    label: "FLE Standard Time",
  },
  {
    value: "Turkey Standard Time",
    short: "TDT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Istanbul",
    utc: ["Europe/Istanbul"],
    _id: "+03:00 Istanbul",
    label: "Turkey Standard Time",
  },
  {
    value: "Israel Standard Time",
    short: "JDT",
    offset: 3,
    isDST: true,
    text: "(UTC+02:00) Jerusalem",
    utc: ["Asia/Jerusalem"],
    _id: "+02:00 Jerusalem",
    label: "Israel Standard Time",
  },
  {
    value: "Libya Standard Time",
    short: "LST",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Tripoli",
    utc: ["Africa/Tripoli"],
    _id: "+02:00 Tripoli",
    label: "Libya Standard Time",
  },
  {
    value: "Jordan Standard Time",
    short: "JST",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Amman",
    utc: ["Asia/Amman"],
    _id: "+03:00 Amman",
    label: "Jordan Standard Time",
  },
  {
    value: "Arabic Standard Time",
    short: "AST",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Baghdad",
    utc: ["Asia/Baghdad"],
    _id: "+03:00 Baghdad",
    label: "Arabic Standard Time",
  },
  {
    value: "Kaliningrad Standard Time",
    short: "KST",
    offset: 3,
    isDST: false,
    text: "(UTC+02:00) Kaliningrad",
    utc: ["Europe/Kaliningrad"],
    _id: "+02:00 Kaliningrad",
    label: "Kaliningrad Standard Time",
  },
  {
    value: "Arab Standard Time",
    short: "AST",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Kuwait, Riyadh",
    utc: ["Asia/Aden", "Asia/Bahrain", "Asia/Kuwait", "Asia/Qatar", "Asia/Riyadh"],
    _id: "+03:00 Kuwait, Riyadh",
    label: "Arab Standard Time",
  },
  {
    value: "E. Africa Standard Time",
    short: "EAST",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Nairobi",
    utc: [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte",
    ],
    _id: "+03:00 Nairobi",
    label: "E. Africa Standard Time",
  },
  {
    value: "Moscow Standard Time",
    short: "MSK",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
    utc: ["Europe/Kirov", "Europe/Moscow", "Europe/Simferopol", "Europe/Volgograd", "Europe/Minsk"],
    _id: "+03:00 Moscow, St. Petersburg, Volgograd, Minsk",
    label: "Moscow Standard Time",
  },
  {
    value: "Samara Time",
    short: "SAMT",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Samara, Ulyanovsk, Saratov",
    utc: ["Europe/Astrakhan", "Europe/Samara", "Europe/Ulyanovsk"],
    _id: "+04:00 Samara, Ulyanovsk, Saratov",
    label: "Samara Time",
  },
  {
    value: "Iran Standard Time",
    short: "IDT",
    offset: 4.5,
    isDST: true,
    text: "(UTC+03:30) Tehran",
    utc: ["Asia/Tehran"],
    _id: "+03:30 Tehran",
    label: "Iran Standard Time",
  },
  {
    value: "Arabian Standard Time",
    short: "AST",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Abu Dhabi, Muscat",
    utc: ["Asia/Dubai", "Asia/Muscat", "Etc/GMT-4"],
    _id: "+04:00 Abu Dhabi, Muscat",
    label: "Arabian Standard Time",
  },
  {
    value: "Azerbaijan Standard Time",
    short: "ADT",
    offset: 5,
    isDST: true,
    text: "(UTC+04:00) Baku",
    utc: ["Asia/Baku"],
    _id: "+04:00 Baku",
    label: "Azerbaijan Standard Time",
  },
  {
    value: "Mauritius Standard Time",
    short: "MST",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Port Louis",
    utc: ["Indian/Mahe", "Indian/Mauritius", "Indian/Reunion"],
    _id: "+04:00 Port Louis",
    label: "Mauritius Standard Time",
  },
  {
    value: "Georgian Standard Time",
    short: "GET",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Tbilisi",
    utc: ["Asia/Tbilisi"],
    _id: "+04:00 Tbilisi",
    label: "Georgian Standard Time",
  },
  {
    value: "Caucasus Standard Time",
    short: "CST",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Yerevan",
    utc: ["Asia/Yerevan"],
    _id: "+04:00 Yerevan",
    label: "Caucasus Standard Time",
  },
  {
    value: "Afghanistan Standard Time",
    short: "AST",
    offset: 4.5,
    isDST: false,
    text: "(UTC+04:30) Kabul",
    utc: ["Asia/Kabul"],
    _id: "+04:30 Kabul",
    label: "Afghanistan Standard Time",
  },
  {
    value: "West Asia Standard Time",
    short: "WAST",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Ashgabat, Tashkent",
    utc: [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives",
    ],
    _id: "+05:00 Ashgabat, Tashkent",
    label: "West Asia Standard Time",
  },
  {
    value: "Yekaterinburg Time",
    short: "YEKT",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Yekaterinburg",
    utc: ["Asia/Yekaterinburg"],
    _id: "+05:00 Yekaterinburg",
    label: "Yekaterinburg Time",
  },
  {
    value: "Pakistan Standard Time",
    short: "PKT",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Islamabad, Karachi",
    utc: ["Asia/Karachi"],
    _id: "+05:00 Islamabad, Karachi",
    label: "Pakistan Standard Time",
  },
  {
    value: "India Standard Time",
    short: "IST",
    offset: 5.5,
    isDST: false,
    text: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    utc: ["Asia/Kolkata", "Asia/Calcutta"],
    _id: "+05:30 Chennai, Kolkata, Mumbai, New Delhi",
    label: "India Standard Time",
  },
  {
    value: "Sri Lanka Standard Time",
    short: "SLST",
    offset: 5.5,
    isDST: false,
    text: "(UTC+05:30) Sri Jayawardenepura",
    utc: ["Asia/Colombo"],
    _id: "+05:30 Sri Jayawardenepura",
    label: "Sri Lanka Standard Time",
  },
  {
    value: "Nepal Standard Time",
    short: "NST",
    offset: 5.75,
    isDST: false,
    text: "(UTC+05:45) Kathmandu",
    utc: ["Asia/Kathmandu"],
    _id: "+05:45 Kathmandu",
    label: "Nepal Standard Time",
  },
  {
    value: "Central Asia Standard Time",
    short: "CAST",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Nur-Sultan (Astana)",
    utc: [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos",
    ],
    _id: "+06:00 Nur-Sultan (Astana)",
    label: "Central Asia Standard Time",
  },
  {
    value: "Bangladesh Standard Time",
    short: "BST",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Dhaka",
    utc: ["Asia/Dhaka", "Asia/Thimphu"],
    _id: "+06:00 Dhaka",
    label: "Bangladesh Standard Time",
  },
  {
    value: "Myanmar Standard Time",
    short: "MST",
    offset: 6.5,
    isDST: false,
    text: "(UTC+06:30) Yangon (Rangoon)",
    utc: ["Asia/Rangoon", "Indian/Cocos"],
    _id: "+06:30 Yangon (Rangoon)",
    label: "Myanmar Standard Time",
  },
  {
    value: "SE Asia Standard Time",
    short: "SAST",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    utc: [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas",
    ],
    _id: "+07:00 Bangkok, Hanoi, Jakarta",
    label: "SE Asia Standard Time",
  },
  {
    value: "N. Central Asia Standard Time",
    short: "NCAST",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Novosibirsk",
    utc: ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"],
    _id: "+07:00 Novosibirsk",
    label: "N. Central Asia Standard Time",
  },
  {
    value: "China Standard Time",
    short: "CST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    utc: ["Asia/Hong_Kong", "Asia/Macau", "Asia/Shanghai"],
    _id: "+08:00 Beijing, Chongqing, Hong Kong, Urumqi",
    label: "China Standard Time",
  },
  {
    value: "North Asia Standard Time",
    short: "NAST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Krasnoyarsk",
    utc: ["Asia/Krasnoyarsk"],
    _id: "+08:00 Krasnoyarsk",
    label: "North Asia Standard Time",
  },
  {
    value: "Singapore Standard Time",
    short: "MPST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Kuala Lumpur, Singapore",
    utc: [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8",
    ],
    _id: "+08:00 Kuala Lumpur, Singapore",
    label: "Singapore Standard Time",
  },
  {
    value: "W. Australia Standard Time",
    short: "WAST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Perth",
    utc: ["Antarctica/Casey", "Australia/Perth"],
    _id: "+08:00 Perth",
    label: "W. Australia Standard Time",
  },
  {
    value: "Taipei Standard Time",
    short: "TST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Taipei",
    utc: ["Asia/Taipei"],
    _id: "+08:00 Taipei",
    label: "Taipei Standard Time",
  },
  {
    value: "Ulaanbaatar Standard Time",
    short: "UST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Ulaanbaatar",
    utc: ["Asia/Choibalsan", "Asia/Ulaanbaatar"],
    _id: "+08:00 Ulaanbaatar",
    label: "Ulaanbaatar Standard Time",
  },
  {
    value: "North Asia East Standard Time",
    short: "NAEST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Irkutsk",
    utc: ["Asia/Irkutsk"],
    _id: "+08:00 Irkutsk",
    label: "North Asia East Standard Time",
  },
  {
    value: "Japan Standard Time",
    short: "JST",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    utc: ["Asia/Dili", "Asia/Jayapura", "Asia/Tokyo", "Etc/GMT-9", "Pacific/Palau"],
    _id: "+09:00 Osaka, Sapporo, Tokyo",
    label: "Japan Standard Time",
  },
  {
    value: "Korea Standard Time",
    short: "KST",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Seoul",
    utc: ["Asia/Pyongyang", "Asia/Seoul"],
    _id: "+09:00 Seoul",
    label: "Korea Standard Time",
  },
  {
    value: "Cen. Australia Standard Time",
    short: "CAST",
    offset: 9.5,
    isDST: false,
    text: "(UTC+09:30) Adelaide",
    utc: ["Australia/Adelaide", "Australia/Broken_Hill"],
    _id: "+09:30 Adelaide",
    label: "Cen. Australia Standard Time",
  },
  {
    value: "AUS Central Standard Time",
    short: "ACST",
    offset: 9.5,
    isDST: false,
    text: "(UTC+09:30) Darwin",
    utc: ["Australia/Darwin"],
    _id: "+09:30 Darwin",
    label: "AUS Central Standard Time",
  },
  {
    value: "E. Australia Standard Time",
    short: "EAST",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Brisbane",
    utc: ["Australia/Brisbane", "Australia/Lindeman"],
    _id: "+10:00 Brisbane",
    label: "E. Australia Standard Time",
  },
  {
    value: "AUS Eastern Standard Time",
    short: "AEST",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Canberra, Melbourne, Sydney",
    utc: ["Australia/Melbourne", "Australia/Sydney"],
    _id: "+10:00 Canberra, Melbourne, Sydney",
    label: "AUS Eastern Standard Time",
  },
  {
    value: "West Pacific Standard Time",
    short: "WPST",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Guam, Port Moresby",
    utc: [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk",
    ],
    _id: "+10:00 Guam, Port Moresby",
    label: "West Pacific Standard Time",
  },
  {
    value: "Tasmania Standard Time",
    short: "TST",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Hobart",
    utc: ["Australia/Currie", "Australia/Hobart"],
    _id: "+10:00 Hobart",
    label: "Tasmania Standard Time",
  },
  {
    value: "Yakutsk Standard Time",
    short: "YST",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Yakutsk",
    utc: ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"],
    _id: "+09:00 Yakutsk",
    label: "Yakutsk Standard Time",
  },
  {
    value: "Central Pacific Standard Time",
    short: "CPST",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Solomon Is., New Caledonia",
    utc: [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape",
    ],
    _id: "+11:00 Solomon Is., New Caledonia",
    label: "Central Pacific Standard Time",
  },
  {
    value: "Vladivostok Standard Time",
    short: "VST",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Vladivostok",
    utc: ["Asia/Sakhalin", "Asia/Ust-Nera", "Asia/Vladivostok"],
    _id: "+11:00 Vladivostok",
    label: "Vladivostok Standard Time",
  },
  {
    value: "New Zealand Standard Time",
    short: "NZST",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Auckland, Wellington",
    utc: ["Antarctica/McMurdo", "Pacific/Auckland"],
    _id: "+12:00 Auckland, Wellington",
    label: "New Zealand Standard Time",
  },
  {
    value: "UTC+12",
    short: "U",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Coordinated Universal Time+12",
    utc: [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis",
    ],
    _id: "+12:00 Coordinated Universal Time+12",
    label: "UTC+12",
  },
  {
    value: "Fiji Standard Time",
    short: "FST",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Fiji",
    utc: ["Pacific/Fiji"],
    _id: "+12:00 Fiji",
    label: "Fiji Standard Time",
  },
  {
    value: "Magadan Standard Time",
    short: "MST",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Magadan",
    utc: ["Asia/Anadyr", "Asia/Kamchatka", "Asia/Magadan", "Asia/Srednekolymsk"],
    _id: "+12:00 Magadan",
    label: "Magadan Standard Time",
  },
  {
    value: "Kamchatka Standard Time",
    short: "KDT",
    offset: 13,
    isDST: true,
    text: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    utc: ["Asia/Kamchatka"],
    _id: "+12:00 Petropavlovsk-Kamchatsky - Old",
    label: "Kamchatka Standard Time",
  },
  {
    value: "Tonga Standard Time",
    short: "TST",
    offset: 13,
    isDST: false,
    text: "(UTC+13:00) Nuku'alofa",
    utc: ["Etc/GMT-13", "Pacific/Enderbury", "Pacific/Fakaofo", "Pacific/Tongatapu"],
    _id: "+13:00 Nuku'alofa",
    label: "Tonga Standard Time",
  },
  {
    value: "Samoa Standard Time",
    short: "SST",
    offset: 13,
    isDST: false,
    text: "(UTC+13:00) Samoa",
    utc: ["Pacific/Apia"],
    _id: "+13:00 Samoa",
    label: "Samoa Standard Time",
  },
];

export default timeZone;
