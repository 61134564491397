// SieraLoaderStyles.js
import styled, { keyframes } from "styled-components";

const transformScale = {
  small: 0.080,
  medium: 0.7,
  large: 1,
};

export const fillLeftToRight = keyframes`
  0% {
    transform: scaleX(0);
    transform-origin: left;
    animation-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  }
  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
`;

export const unfillRightToLeft = keyframes`
  0% {
    transform: scaleX(1);
    transform-origin: right;
    animation-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 300px;
`;

export const Line = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  float: left;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    transform-origin: left;
    transform: scaleX(0);
    animation:
      ${fillLeftToRight} 2s forwards,
      ${unfillRightToLeft} 1s forwards;
    z-index: 1;
  }

  &.line2 {
    width: 90%;
    margin: 110px 0;
  }

  &.line1::before {
    animation-delay: 0.2s, 2s;
    animation-duration: 0.8s, 0.7s;
    background-color: #1f3c5f;
  }

  &.line2::before {
    animation-delay: 0.4s, 1.8s;
    animation-duration: 0.3s, 0.6s;
    background-color: #f7ae10;
  }

  &.line3::before {
    animation-delay: 0.6s, 1.7s;
    animation-duration: 0.4s, 0.5s;
    background-color: #1f3c5f;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  svg {
    height: 50px;
    margin: 0 5px;
  }

  ${LoaderWrapper} {
    margin: 0px 5px;
    width: 35px;
    height: 63px;
    ${Line} {
      width: 100%;
      height: 60px;
      margin: 7px 0;
      &.line2 {
        width: 80%;
      }
    }
  }
`;

export const LoaderText = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 20px;
  letter-spacing: 9px;
  margin-left: 4px;
  text-transform: uppercase;
`;

export const LoaderWithText = styled.div`
  float: left;
  text-align: center;
`;

export const LoaderContainer = styled.div`
  /*  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 70vh;
  width: 100%;*/

  position: fixed;
  top: 0;
  right: 0;
  width: 86vw;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 400;

  transform: ${({ size }) => `scale(${transformScale[size]})`};
`;
