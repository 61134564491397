import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Table, Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { Trans, useTranslation } from "react-i18next";
import BreadCrumb from "../../../../components/BreadCrumb.component";
import Dropdown from "../../../../components/DropdownFilter.component";
import Input from "../../../../components/Input";
import DateTimeRangePicker from "../../../../components/DateTimeRangePicker.component";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import {
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetId,
} from "../../../../redux/dropdownFilters/action";
import { getErrorLogs } from "../../../../redux/errors/action";

import "./ErrorLogs.css";
import ExportToCSV from "./ExportToCSV.component";

import errorDescription from "../../../../constant/errorDescription";
import { ErrorLogsStyledDiv } from "./ErrorLogs.component.styles";
import ErrorLogsTable from "./ErrorLogsTableWrapperComponent/ErrorLogsTable.component";
import FiltersComponent from "./Filters.Component";

const ErrorLogs = ({
  history,
  updateActiveNavItem,
  isSuperAdmin,
  isSiteAdmin,
  dropdownAccountId,
  dropdownWorksiteId,
  dropdownAccountList,
  dropdownWorksiteList,
  dropdownAssetList,
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetId,
  getErrorLogs,
  dropdownAssetId,
  errorLogs,
  totalCount,
  errorLogLoading,
  loginDetails,
  currentTimeZone,
}) => {
  const { t } = useTranslation();
  const [currentActiveKey, setCurrentActiveKey] = useState("");
  const [localAccountId, setLocalAccountId] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [dateDropdown, setDateDropdown] = useState(2);
  const [dateTimeChange, confirmDateTimeChange] = useState(false);
  const [date, setDate] = useState({
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    // startTime: moment(Date.now()).subtract({ minute: 15 }).format("HH:mm"),
    // endTime: moment(Date.now()).format("HH:mm"),
    startTime: "00:00",
    endTime: "23:59",
  });
  const [downloadReportSet, addDownloadReportSet] = useState(new Set());
  const [isAllSelected, setSelectAll] = useState(false);
  const [isDownloadAllSelected, setIsDownloadAllSelected] = useState(false);
  const [selectedErrorDescription, setSelectedErrorDescription] = useState("all");

  const handleCheckboxClick = (e) => {
    const newDownloadReportSet = new Set(downloadReportSet);

    if (e.target.checked) {
      newDownloadReportSet.add(e.target.value);
    } else {
      newDownloadReportSet.delete(e.target.value);
    }
    addDownloadReportSet(newDownloadReportSet);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newDownloadReportSet = new Set(downloadReportSet);
      errorLogs.forEach((data, i) => {
        newDownloadReportSet.add(data._id);
      });
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(true);
    } else {
      const newDownloadReportSet = new Set();
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    const startResultNumber = pageSize * (pageNo - 1);
    const endResultNumber =
      parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalCount
        ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
        : totalCount;
    const currentTotalReports = endResultNumber - startResultNumber;

    if (downloadReportSet.size === currentTotalReports && currentTotalReports !== 0) {
      setSelectAll(true);
    } else {
      setIsDownloadAllSelected(false);
      setSelectAll(false);
    }
  }, [downloadReportSet]);

  const handlePageLength = (e) => {
    addDownloadReportSet(new Set());
    setPageSize(e.target.value);
  };

  const previousPage = () => {
    addDownloadReportSet(new Set());
    if (pageNo == 0) {
      return;
    }
    setPageNo(pageNo - 1);
  };

  const nextPage = () => {
    addDownloadReportSet(new Set());
    if (pageNo * pageSize > totalCount) {
      return;
    }
    setPageNo(pageNo + 1);
  };

  const toogleDropdown = (e, eventKey) => {
    if (e.target.classList.contains("form-check-input")) {
      return;
    }
    if (eventKey != currentActiveKey) {
      setCurrentActiveKey(eventKey);
    } else {
      setCurrentActiveKey("");
    }
  };

  useEffect(() => {
    updateActiveNavItem("errorLogs");
  }, []);

  const dateTypeOption = [
    { _id: 2, label: "Today" },
    { _id: 3, label: "Yesterday" },
    { _id: 4, label: "Last 3 Days" },
    { _id: 5, label: "Last 7 Days" },
    { _id: 6, label: "Last 30 Days" },
    { _id: 1, label: "Custom" },
  ];

  const changeDateTypeDropdown = (value) => {
    handleClearAllSelection();
    if (value == 1) {
      setDate({
        ...date,
        // startTime: moment(Date.now()).subtract({ minute: 15 }).format("HH:mm"),
        // endTime: moment(Date.now()).format("HH:mm"),
        startTime: "00:00",
        endTime: "23:59",
      });
    } else if (value == 2) {
      setDate({
        ...date,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        startTime: "00:00",
        endTime: "23:59",
      });
    } else if (value == 3) {
      setDate({
        ...date,
        startDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
        startTime: "00:00",
        endTime: "23:59",
      });
    } else if (value == 4) {
      setDate({
        ...date,
        startDate: moment().subtract(2, "day").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        startTime: "00:00",
        endTime: "23:59",
      });
    } else if (value == 5) {
      setDate({
        ...date,
        startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        startTime: "00:00",
        endTime: "23:59",
      });
    } else if (value == 6) {
      setDate({
        ...date,
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        startTime: "00:00",
        endTime: "23:59",
      });
    }
    setDateDropdown(value);
  };

  useEffect(() => {
    getErrorLogs({
      pageNo: pageNo,
      pageSize: pageSize,
      accountId: localAccountId,
      siteId: dropdownWorksiteId,
      assetId: dropdownAssetId,
      startDate: moment(`${date.startDate}T${date.startTime}`).utc().toISOString(),
      endDate: moment(`${date.endDate}T${date.endTime}`).utc().toISOString(),
      errorDescription: selectedErrorDescription || "all",
    });
  }, [
    dropdownAssetId,
    localAccountId,
    dropdownWorksiteId,
    dateTimeChange,
    pageNo,
    pageSize,
    dateDropdown,
    errorDescription,
  ]);

  const handleClearAllSelection = () => {
    setIsDownloadAllSelected(false);
    addDownloadReportSet(new Set());
  };

  return (
    <>
      <ErrorLogsStyledDiv>
        <BreadCrumb />
        <br />

        <FiltersComponent
          isSuperAdmin={isSuperAdmin}
          isSiteAdmin={isSiteAdmin}
          dropdownAccountList={dropdownAccountList}
          dropdownWorksiteList={dropdownWorksiteList}
          dropdownAssetList={dropdownAssetList}
          dropdownAccountId={dropdownAccountId}
          dropdownWorksiteId={dropdownWorksiteId}
          dropdownAssetId={dropdownAssetId}
          localAccountId={localAccountId}
          setDropdownAccountId={setDropdownAccountId}
          setDropdownWorksiteId={setDropdownWorksiteId}
          setDropdownAssetId={setDropdownAssetId}
          setLocalAccountId={setLocalAccountId}
          handleClearAllSelection={handleClearAllSelection}
          selectedErrorDescription={selectedErrorDescription}
          setSelectedErrorDescription={setSelectedErrorDescription}
          dateDropdown={dateDropdown}
          setDateDropdown={setDateDropdown}
          date={date}
          setDate={setDate}
          dateTimeChange={dateTimeChange}
          confirmDateTimeChange={confirmDateTimeChange}
          downloadReportSet={downloadReportSet}
          errorLogs={errorLogs}
          isDownloadAllSelected={isDownloadAllSelected}
          loginDetails={loginDetails}
          changeDateTypeDropdown={changeDateTypeDropdown}
        />
      </ErrorLogsStyledDiv>

      <br />

      <ErrorLogsTable
        data={errorLogs}
        pageSize={pageSize}
        pageNo={pageNo}
        totalPageReport={totalCount}
        nextPage={nextPage}
        previousPage={previousPage}
        handlePageLength={handlePageLength}
        handleSelectAll={handleSelectAll}
        isAllSelected={isAllSelected}
        handleCheckboxClick={handleCheckboxClick}
        downloadReportSet={downloadReportSet}
        noDataMessage={localAccountId ? "No Error Logs" : "Please select an account to view error logs"}
        paginationOptions={[10, 25, 50, 100].map((value) => ({ name: value, value }))}
        currentTimeZone={currentTimeZone}
        loading={errorLogLoading}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  billingInfo: state.billing.BillingInfo,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAssetList: state.dropdownFilters.assetList,
  dropdownAccountId: state.dropdownFilters.accountId,
  dropdownWorksiteId: state.dropdownFilters.worksiteId,
  dropdownAssetId: state.dropdownFilters.assetId,
  errorLogs: state.errors.errorLog,
  errorLogLoading: state.errors.errorLogLoading,
  totalCount: state.errors.errorLogCount,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (siteId) => dispatch(setDropdownWorksiteId(siteId)),
  setDropdownAssetId: (assetId) => dispatch(setDropdownAssetId(assetId)),
  getErrorLogs: (filterData) => dispatch(getErrorLogs(filterData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorLogs);
